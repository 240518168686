
.text-investor-green {
  --text-opacity: 1;
  color: #36d887;
  
}

.bg-investor-green {
  --bg-opacity: 1;
  background-color: #36d887;
  background-color: rgba(54, 216, 135, var(--bg-opacity));
}

.bg-investor-red {
  --bg-opacity: 1;
  background-color: #ef2942;
  background-color: rgba(239, 41, 66, var(--bg-opacity));
}

.text-investor-red {
  --text-opacity: 1;
  color: #ef2942;
  color: rgba(239, 41, 66, var(--bg-opacity));
}

.border-investor-blue {
  --border-opacity: 1;
  border-color: #003dff;
  border-color: rgba(0, 61, 255, var(--bg-opacity));
}

.bg-investor-blue {
  --bg-opacity: 1;
  background-color: #003dff;
  background-color: rgba(0, 61, 255, var(--bg-opacity));
}

.text-investor-blue {
  --text-opacity: 1;
  color: #003dff;
  color: rgba(0, 61, 255, var(--text-opacity));
}

/* the heaven update */

.green-icon {
  color: #23A534;
  opacity: 1 !important;
}

.heaven-cta-box-buttons {
  float: right;
  display: flex;
  padding-top: 10px;
}

.heaven-cta-box-icon {
  width: 15px !important;
  margin-right: 8px;
  opacity: .5;
}

.heaven-cta-box-item {
  color: #5e6e77;
  font-size: 15px;
  margin-bottom: 11px;
}

.heaven-cta-box-title {
  color: #1d2531;
  font-weight: bold;
  font-size: 18px;
}

.heaven-dash-cta-box {
  background-color: white;
  margin-bottom: 36px;
  margin-right: 12px;
  border-radius: 7px;
  border: 1px solid #dfe8ef;
  padding: 12px 12px 54px 12px;
  box-shadow: rgba(10,40,91,.035) 0px 2px 5px, rgba(10,40,91,.035) 0px 5px 12px;
}

.heaven-refresh-button-w-text {
  display: flex;
}

.heaven-dash-cta {
  background-color: #1d2531;
  color: white;
}

.heaven-dash-body-intro-title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 12px;
  color: #1d2531;
}

.heaven-dash-body-intro-body {
  margin-bottom: 12px;
  color: #5e6e77;
}

.heaven-dash-body-intro-title-white {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 12px;
  color: white;
}

.heaven-dash-body-intro-body-white {
  margin-bottom: 12px;
  opacity: .8;
  color: white;
}

.dash-body-intro-button {
  margin-bottom: 12px;
}

.heaven-dash-body-intro-grey {
  padding: 18px 18px 6px 18px;
  background-color: #1d2531;
  color: white;
  border-radius: 7px;
  border: 1px solid #dfe8ef;
  width: 100%;
  margin-bottom: 2em;
  text-align: left;
  box-shadow: rgba(10,40,91,.035) 0px 2px 5px, rgba(10,40,91,.035) 0px 5px 12px;
}

.heaven-dash-body-intro {
  padding: 18px 18px 6px 18px;
  background-color: white;
  border-radius: 7px;
  border: 1px solid #dfe8ef;
  width: 100%;
  margin-bottom: 2em;
  text-align: left;
  box-shadow: rgba(10,40,91,.035) 0px 2px 5px, rgba(10,40,91,.035) 0px 5px 12px;
}

.Dropdown__root___3ALmx select {
  border: 1px solid #dfe8ef !important;
  padding-left: 8px !important;
  border-radius: 7px !important;
  font-weight: bold !important;
}

.Dropdown__root___3ALmx .Dropdown__value___34Py9 {
  border-radius: 7px !important;
  border: none !important;
}

.Button__root___1gz0c {
  display: inline-block !important;
  margin: 0px 0px 0px 0px !important;
  padding: 3px 4px 3px 4px !important;
  height: 30px !important;
  line-height: 22px !important;
  background: white !important;
  border: 1px solid #dfe8ef !important;
  color: #4d5a60 !important;
  white-space: nowrap !important;
}

.ButtonGroup__root___3lEAn>div:first-child>button {
  border-top-left-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
}

.ButtonGroup__root___3lEAn>div:last-child>button {
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}

.ButtonGroup__root___3lEAn {
  margin-bottom: 0px !important;
}

.EditorToolbar__root___3_Aqz {
  margin: 0px !important;
  padding: 12px !important;
  border-bottom: 1px solid #eff3f7 !important;
}

.RichTextEditor__root___2QXK- {
  border: solid 1px #dfe8ef !important;
  border-radius: 7px !important;
  box-shadow: rgba(10,40,91,.035) 0px 2px 5px, rgba(10,40,91,.035) 0px 5px 12px !important;
}

.heaven-req-card-content {
  margin-bottom: 0px;
  font-size: 18px !important;
}

.heaven-margin-bottom {
  margin-bottom: 12px;
}

.heaven-margin-right {
  margin-right: 12px;
}

.heaven-req-bio {
  margin: 0px;
  padding-bottom: 12px;
}

.heaven-req-card-dropdown-content {
  background-color: white;
  border: 1px solid #dfe8ef !important;
  border-radius: 7px;
  margin-top: 5px;
  width: auto;
  min-width: 300px;
  z-index: 1500;
  padding: 12px 12px 0px 12px;
  box-shadow: rgba(10,40,91,.035) 0px 2px 5px, rgba(10,40,91,.035) 0px 5px 12px;
}

.journalist-req-name-title {
  display: block;
  padding-top: 3px;
  margin-left: 40px;
  font-size: 14px;
}

.journalist-req-name {
  color: #1d2531;
  font-weight: bold;
  font-size: 18px;
  margin: 0;
  padding-bottom: 3px;
}

.heaven-req-avatar-placeholder {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  background: #5e6e77;
  position: absolute;
  z-index: 1;
}

.request-card-picture {
  border-radius: 100px;
  height: 30px;
  width: 30px;
  position: absolute;
  z-index: 2;
}

.ais-Pagination-item--disabled {
  opacity: .5;
  color: #5e6e77;
}

.list-title-edit-icon {
  padding-left: 9px;
  margin-left: 1px;
  color: #4d5a60;
  opacity: .35;
}

.heaven-list-title-plus-edit {
  text-align: center;
}

.heaven-list-title {
  font-size: 26px;
  color: #1d2531;
  font-weight: bold;
}

.heaven-list-subtitle {
  color: #5e6e77;
  padding-top: 8px;
  margin-bottom: 16px;
  font-size: 18px;
}

.heaven-upper-section-full {
  padding-top: 100px;
  margin-top: auto;
  text-align: center;
}

.heaven-upper-section {
  max-width: 800px;
  margin: auto;
  text-align: center;
}

.heaven-top-buttons-div {
  float: right;
  margin-bottom: 50px;
}

.heaven-top-buttons-div-center {
  margin-bottom: 50px;
}

.heaven-list-card {
  background-color: white;
  height: 100px;
  width: 100%;
}

.ais-Pagination-item--page {
  font-size: 85%;

}

.ais-Pagination-item--nextPage {
  margin-left: 8px;
}

.ais-Pagination-item--previousPage {
  margin-right: 8px;
}

.heaven-bottom-pag-div {
  display: inline-block;
  margin: auto !important;
  text-align: center;
  align-items: center;
  float: right !important;
}

.heaven-bottom-pagination {
  background-color: none !important;
  color: #4A4A4A;
  font-size: 18px !important;
  font-weight: bold;
  border: none;
  border-radius: 7px;
  width: auto !important;
  margin-top: 30px !important;
  margin-bottom: 60px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center;
  box-shadow: rgba(191, 191, 191, 0.15) 0px 2px 2px 1px;
}

.heaven-search-pag-div {
  display: inline-block;
  width: 100%;
}

.heaven-top-pagination {
  background-color: none !important;
  color: #4A4A4A;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 7px;
  margin-left: 12px;
  text-align: center;
  box-shadow: rgba(191, 191, 191, 0.15) 0px 2px 2px 1px;
}

.heaven-hover-location {
  display: none;
  z-index: 1000;
  color: black;
}

.heaven-hover-location:hover {
  font-weight: bold;
  color: black;
  display: inline;
  z-index: 1000;
}

.heaven-avatar-placeholder {
  border-radius: 50%;
  height: 65px;
  width: 65px;
  background: #5e6e77;
  position: absolute;
  z-index: 1;
}

a {
  color: #4d5a60;
}

.heaven-mini-div {
  max-width: 800px;
  padding: 24px 24px;
  margin: auto;
  margin-top: 82px;
}

.heaven-mini-div-for-center {
  max-width: 800px;
  padding: 24px 24px;
  margin: auto;
}

.heaven-div {
  max-width: 1120px;
  padding: 24px 24px;
  margin: auto;
  margin-top: 82px;
}

.heaven-nav {
  width: 100vw;
  background-color: white;
  height: 56px;
  border-bottom: solid 1px #dfe8ef;
  box-shadow: rgba(10,40,91,.035) 0px 2px 5px, rgba(10,40,91,.035) 0px 5px 12px;
  position: fixed;
  top: 0px;
  z-index: 100000;
}

.heaven-nav-content {
  max-width: 1120px;
  padding: 0px 24px 0px 24px;
  margin: auto;
  height: 85px;
}

.heaven-logo {
  height: 32px;
  margin-top: 11px;
}

.heaven-nav-links-right {
  float: right;
  align-items: center;
  margin-top: 17px;
}

.heaven-nav-link {
  padding: 12px;
  font-size: 15px;
  font-weight: bold;
  color: #5e6e77;
}

.heaven-nav-link:hover {
  color: #1d2531;
}

.heaven-nav-link-icon {
  margin-right: 7px;
  opacity: .35;
}

.heaven-nav-link-icon-lock {
  margin-left: 7px;
  color: #ef2942;
}

.heaven-nav-link-icon-right {
  margin-left: 7px;
  opacity: .5;
}

.heaven-nav-button {
  font-size: 15px;
  padding: 8px 12px;
  background-color: #003dff;
  margin-left: 12px;
  border-radius: 7px;
  color: white;
  font-weight: 600;
  box-shadow: rgba(0,61,255,.15) 0px 2px 2px 1px;
}

.heaven-nav-button:hover {
  color: white;
  background-color: #2261ff;
}

.heaven-nav-button-icon {
  height: 12px;
  margin-bottom: 1px;
  margin-right: 5px;
  opacity: .5;
}

.heaven-nav-button-icon-right {
  margin-bottom: 1px;
  margin-left: 5px;
  opacity: .5;
  height: 12px;
}

.heaven-nav-sole-icon-div {
  padding: 12px;
  margin-left: 12px;
  padding-right: 0px;
  margin-top: -16px;
  font-size: 24px;
  color: #5e6e77;
  float: right;
}

.heaven-nav-sole-icon {
  color: #5e6e77;
  opacity: .5;
}

.heaven-nav-sole-icon:hover {
  color: #1d2531;
  opacity: .5;
}

.heaven-search-filters {
  background-color: white;
  margin-right: 12px;
  border-radius: 7px;
  border: solid 1px #dfe8ef;
  z-index: 1;
  box-shadow: rgba(10,40,91,.035) 0px 2px 5px, rgba(10,40,91,.035) 0px 5px 12px;
}

.heaven-cover-filters {
  background-color: #F8FCFF;
  width: 25%;
  height: 4000px;
  z-index: 2;
  position: absolute;
  padding: 0px;
  margin: 12px;
  margin-top: 440px;
  opacity: .5;
  border-radius: 7px;
}

.heaven-search-filter-section {
  padding: 0px 0px 13px 0px;
  border-bottom: 1px solid #eff3f7;
}

.heaven-search-filter-subtitle {
  padding: 18px 18px 12px 18px;
  margin: 0px;
  font-weight: bold;
}

.heaven-nav-dropdown-content {
  background-color: white;
  border: 1px solid #dfe8ef !important;
  border-radius: 7px;
  margin-top: 9.5px;
  max-width: 200px;
  z-index: 1000;
  padding: 5px 0px 5px 0px;
  box-shadow: rgba(10,40,91,.035) 0px 2px 5px, rgba(10,40,91,.035) 0px 5px 12px;
}

.heaven-nav-link-dropdown-content {
  background-color: white;
  border: 1px solid #dfe8ef !important;
  border-radius: 7px;
  margin-top: 7.5px;
  max-width: 200px;
  z-index: 1500;
  padding: 5px 0px 5px 0px;
  box-shadow: rgba(10,40,91,.035) 0px 2px 5px, rgba(10,40,91,.035) 0px 5px 12px;
}

.heaven-card-dropdown-content {
  background-color: white;
  border: 1px solid #dfe8ef !important;
  border-radius: 7px;
  margin-top: 5px;
  min-width: 250px;
  z-index: 1500;
  padding: 5px 0px 5px 0px;
  box-shadow: rgba(10,40,91,.035) 0px 2px 5px, rgba(10,40,91,.035) 0px 5px 12px;
}

.heaven-dropdown-content {
  background-color: white;
  border: 1px solid #dfe8ef !important;
  border-radius: 7px;
  margin-top: 15px;
  min-width: 250px;
  z-index: 1000;
  padding: 5px 0px 5px 0px;
  box-shadow: rgba(10,40,91,.035) 0px 2px 5px, rgba(10,40,91,.035) 0px 5px 12px;
}

.heaven-dropdown-item {
  padding-left: 12px;
  height: auto;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  background-color: white;
  color: #1d2531;
}

.heaven-dropdown-item:hover {
  background-color: #f9fafc !important;
  color: #003dff !important;
}

.heaven-nav-link-dropdown-item {
  padding-left: 12px;
  height: auto;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  background-color: white;
  color: #5e6e77;
}

.heaven-nav-link-dropdown-item:hover {
  background-color: #f9fafc !important;
  color: #1d2531 !important;
}

.heaven-dropdown-content-second-half {
  padding: 15px 0px 13px 0px;
  border-top: 1px solid #eff3f7;
  margin-top: 4px;
  text-align: center;
}

.heaven-dropdown-content-second-half-links {
  padding: 5px 0px 0px 0px;
  border-top: 1px solid #eff3f7;
  margin-top: 4px;
  text-align: center;
}

.heaven-card-dropdown-item {
  height: 36px !important;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  background-color: white;
  color: #5e6e77 !important;
  cursor: pointer;
}

.heaven-card-dropdown-item:hover {
  color: #1d2531 !important;
  background-color: #f9fafc !important;
}

.heaven-page-dropdown-item {
  height: 36px !important;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  background-color: white;
  color: #5e6e77 !important;
  cursor: pointer;
}

.heaven-page-dropdown-item:hover {
  background-color: #f9fafc !important;
  color: #003dff !important;
  cursor: pointer;
}

.heaven-page-dropdown-button {
  font-size: 12px;
  padding: 8px 12px;
  background-color: white;
  border: solid 1px #dfe8ef;
  color: #4d5a60;
  border-radius: 7px;
  font-weight: 600;
  box-shadow: rgba(191, 191, 191, 0.15) 0px 2px 2px 1px;
}

.heaven-page-dropdown-button:hover {
  color: #1d2531;
}

.heaven-page-dropdown-item-no-icon {
  height: 36px !important;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  background-color: white;
  color: #5e6e77 !important;
  cursor: pointer;
}

.heaven-page-dropdown-item-no-icon:hover {
  background-color: #f9fafc !important;
  color: #1d2531 !important;
}

.heaven-dropdown-item-icon {
  width: 15px !important;
  margin-right: 12px;
}

.heaven-page-dropdown-item-icon {
  width: 15px !important;
  margin-right: 12px;
  opacity: .5;
}

.heaven-search-box {
  border-radius: 7px;
}

.heaven-card-footer {
  width: 100%;
  display: flex;
  border-top: 1px solid #eff3f7;
  background-color: #fbfdff;
  padding: 8px;
  border-bottom-left-radius: 6.5px;
  border-bottom-right-radius: 6.5px;
  height: auto;
}

.heaven-card-footer-columns {
  width: 100%;
}

.heaven-card-footer-buttons {
  float: left;
  padding: 8px 0px 8px 0px;
  width: 100%;
  margin-left: 8.5px;
}

.heaven-card-footer-buttons-right {
  float: right !important;
  padding: 8px 0px 8px 0px;
  width: 100%;
  margin-right: -15.5px;
}

.heaven-card-cta-button {
  font-size: 15px;
  padding: 10px 15px;
  background-color: #003dff;
  border: solid 1px #003dff;
  margin-left: 12px;
  border-radius: 7px;
  color: white;
  font-weight: 600;
  box-shadow: rgba(0,61,255,.15) 0px 2px 2px 1px;
  cursor: pointer;
}

.heaven-card-cta-button:hover {
  color: white;
  background-color: #2261ff;
  border: solid 1px #2261ff;
}

.heaven-card-success-button {
  font-size: 15px;
  padding: 10px 19px 10px 15px;
  background-color: #003dff;
  border: solid 1px #003dff;
  margin-left: 12px;
  border-radius: 7px;
  color: white;
  font-weight: 600;
  box-shadow: rgba(0,61,255,.15) 0px 2px 2px 1px;
}

.heaven-card-success-button:hover {
  color: white;
  background-color: #2261ff;
  border: solid 1px #2261ff;
}

.heaven-card-white-button {
  font-size: 15px;
  padding: 8px 12px;
  background-color: white;
  float: left;
  border: solid 1px #dfe8ef;
  color: #4d5a60;
  border-radius: 7px;

  font-weight: 600;
  box-shadow: rgba(191, 191, 191, 0.15) 0px 2px 2px 1px;
}

.heaven-journalist-card {
  background-color: white;
  border-radius: 7px;
  border: 1px solid #dfe8ef;
  width: 100%;
  margin-bottom: 2em;
  text-align: left;
  box-shadow: rgba(10,40,91,.035) 0px 2px 5px, rgba(10,40,91,.035) 0px 5px 12px;
}

.heaven-contact-card-subheader {
  font-weight: bold;
  color: #1d2531;
  padding-bottom: 6px;
}

.heaven-beats-tag {
  margin-right: 4px;
  opacity: .25;
}

.heaven-beats-span {
  margin-left: 4px;
}

.heaven-contact-card-location {
  margin-bottom: 10px;
  font-weight: bold;
}

.heaven-contact-card-flag {
  height: 15px;
  border-radius: 2.5px;
  position: absolute;
  z-index: 5;
}

.heaven-contact-card-tags-full {
  margin: 0px;
  padding: 0px;
}

.heaven-contact-card-tags {
  padding: 18px 18px 10px 18px;
  border-top: 1px solid #eff3f7;
}

.heaven-contact-card-url-tag {
  margin-left: 12px;
  margin-top: -4px;
  font-size: 12px;
  padding: 6px 9px;
  background-color: white;
  border: solid 1px #dfe8ef;
  color: #4d5a60;
  border-radius: 7px;
  display: flex;
  font-weight: 600;
  box-shadow: rgba(191, 191, 191, 0.1) 0px 2px 2px 1px;
}

.heaven-content-card-url-text {
  margin-bottom: 0px;
  margin-top: 2px;
}

.heaven-page-cta-button {
  font-size: 16px;
  padding: 10px 12px;
  background-color: #003dff;
  border: solid 1px #003dff;
  margin-left: 12px;
  border-radius: 7px;
  color: white;
  font-weight: 600;

  box-shadow: rgba(0,61,255,.15) 0px 2px 2px 1px;
}

.heaven-page-cta-button:hover {
  color: white;
  background-color: #2261ff;
  border: solid 1px #2261ff;
  text-decoration: none;
}

.heaven-page-success-button {
  font-size: 16px;
  padding: 10px 12px 10px 9px;
  background-color: #003dff;
  border: solid 1px #003dff;
  margin-left: 12px;
  border-radius: 7px;
  color: white;
  font-weight: 600;
  box-shadow: rgba(191, 191, 191, 0.15) 0px 2px 2px 1px;
}

.heaven-page-success-button:hover {
  color: white;
}

.heaven-card-page-white-button {
  font-size: 16px;
  padding: 10px 12px;
  background-color: white;
  border: solid 1px #dfe8ef;
  color: #4d5a60;
  border-radius: 7px;
  font-weight: bold;
  box-shadow: rgba(191, 191, 191, 0.15) 0px 2px 2px 1px;
}

.heaven-card-page-white-button:hover {
  color: #1d2531;
}

.ais-RefinementList-item {
  padding: 6px 0px 0px 18px;
}

.ais-RefinementList-item:hover {
  background-color: #f9fafc;
  color: #1d2531;
  font-weight: bold;
}

.ais-RefinementList-item--selected {
  background-color: #f9fafc;
  color: #1d2531;
  font-weight: bold;
}

.ais-RefinementList-checkbox {
  border: solid 1px green;
}

.ais-RefinementList-label {
  width: 300px;
}

input.ais-RefinementList-checkbox {
  border: solid 1px red;
}

.heaven-contact-card-tag {
  background-color: white;
  border: solid 1px #eff3f7;
  display: flex;
  padding: 6px 8px 2px 8px;
  margin: 0px 10px 10px 0px;
  color: #4d5a60;
  font-size: 13px;
  border-radius: 7px;
  font-weight: bold;
}

/* end of the heaven update */

/* home */

.home-feature-icon {
  height: 35px;
  margin-right: 10px;
}

.home-feature-title {
  font-weight: bold;
  color: black;
  font-size: 21px;
  padding-top: 4.5px;
}

.new-standard-button-fas-icon-right {
  color: rgba(164,179,186,.5);
  margin-left: 9px;
}

.home-body-link {
  margin-bottom: 10px;
  font-weight: bold;
  color: #1d2531;
}

.home-body-link:hover {
  color: black;
}

.home-body-links {
  margin-top: 24px;
  margin-bottom: -10px;
  display: inline-block;
}

.is-dash-body-intro {
  padding: 3em;
  border-bottom: 1px solid #dfe8ef;
  border-right: 1px solid #dfe8ef;
}

.is-dash-body-intro:hover {
  background-color: rgba(255,255,255,.35);
}

.button-cta-alt-icon-right {
  margin-left: 7px;
  color: white;
  opacity: .5;
}

.top-nav-cta {
  padding: 6px 10px 6px 6px;
  background-color: #003dff;
  border-radius: .25em;
  border: none;
  color: white;
  font-weight: bold;
  position: fixed;
  right: 48px;
  top: 19px;
  display: flex;
  z-index: 1000;
}

.button-cta-alt-icon {
  margin-right: 10px;
  color: white;
  opacity: .5;
}

.button-cta-blue-alt {
  background-color: #003dff !important;
  color: white !important;
  border: none !important;
}

.button-cta-alt {
  background-image: linear-gradient(0deg,#354052 0,#4A5971 100%);
  color: white !important;
  border: none !important;
}

.has-margin-right {
  margin-right: 12px;
}

.home-buttons {
  margin-top: 24px;
  margin-bottom: 12px;
}

.is-dash-home-intro {
  padding: 97px 3em 1.5em 3em;
  border-bottom: 1px solid #dfe8ef;
  background-color: white;
}

.home-intro-title {
  margin-bottom: 21px;
  font-size: 21px;
  font-weight: bold;
  color: black;
}

/* end home */

/* new list */

.is-dash-left-lists-card {
  background-color: none;
  color: #1d2531;
  padding: 1.5em 1.5em 0em 1.5em;
  margin: 0em 1em 0em 0em !important;
  border: solid 1px #dfe8ef;
  border-radius: .4em;
  float: left;
  width: 100%;
}

.is-fixed-top-right {
  position: fixed;
  right: 48px;
  top: 16px;
  display: flex;
}

.is-dark-cta-alt {
  background-image: linear-gradient(0deg,#354052 0,#4A5971 100%);
  border: solid 1px #354052 !important;
  color: white !important;
}

.list-contact-span {
  font-size: 13px;
  background-color: #eff3f7;
  height: auto;
  display: inline;
  padding: 4px 8px 4px 8px;
  height: 25px;
  border-radius: 15px;
  margin-top: 3px;
  font-weight: bold;
  margin-left: 12px;
  color: #5e6e77;
}

.sequence-card-footer-button-remove {
  padding: 14px 18px 14px 18px;
  border-right: 1px solid #dfe8ef;
  font-weight: bold;
  color: #ef2942;
  display: flex;
  cursor: pointer;
}

.dash-left-cta-button {
  background-image: linear-gradient(0deg,#354052 0,#4A5971 100%);
  border: none;
  font-size: 15px;
  border-radius: .25em;
  color: white;
  cursor: pointer;
  font-weight: bold;
  margin-right: 10px;
}

.dash-left-cta-button:hover {
  color: white;
  border: none;
}

/* end new list */

/* campaign analytics */

.refresh-stats-text {
  font-style: italic;
  padding-top: 10px;
  padding-left: 10px;
  font-size: 15px;
  margin-bottom: 0;
  float: left !important;
  color: #5e6e77;
}

.new-standard-button-fas-icon {
  color: #a4b3ba;
  margin-right: 9px;
}

.stats-buttons {
  display: flex;
  margin-bottom: 30px;
}

.new-standard-button {
  background-color: white;
  border: 1px solid #dfe8ef;
  font-size: 1em;
  padding: .5em .75em .5em .75em;
  border-radius: .25em;
  color: #1D2531;
  cursor: pointer;
  font-weight: bold;
}

.new-standard-button:hover {
  color: black;
  border: 1px solid #dfe8ef;
  background-color: rgba(255,255,255,.4);
  text-decoration: none;
}

.grid-list-row-right-icon {
  margin-top: 4px;
  height: 20px;
}

.grid-list-row-cta {
  font-size: 10px;
  padding: 2px 6px 2px 6px;
  border-radius: 10px;
  background-color: black;
  color: white;
  float: right;
}

.grid-list-bottom {
  height: 8px;
  background-color: #fbfdff;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.grid-list-top {
  padding: 12px;
  font-weight: bold;
  color: #1d2531;
  background-color: #fbfdff;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom: solid 1px #dfe8ef;
}

.grid-list-row-text {
  padding: 3.5px;
}

.grid-list-row-icon {
  margin: 6px 7px 0px 0px;
  opacity: .35;
}

.grid-list-row-icon-right {
  margin: 6px 0px 0px 7px;
  opacity: .35;
}

.grid-list-row {
  width: 100%;
  border-bottom: solid 1px #eff3f7;
  padding: 12px;
  margin: 0px;
  background-color: white;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  cursor: pointer;
  color: #5e6e77;
}

.grid-list-row:hover {
  color: #003dff;
  background-color: #fbfdff;
  text-decoration: none;
}

.grid-list {
  margin-top: 12px;
  width: 100%;
  border: solid 1px #dfe8ef;
  border-radius: 7px;
  height: 0%;
  box-shadow: rgba(10,40,91,.035) 0px 2px 5px, rgba(10,40,91,.035) 0px 5px 12px;
}

/* end campaign analytics */

/* bulk dash styling */

.dropdown-content-campaign-reverse {
  background-color: white;
  border: 1px solid #dfe8ef !important;
  color: white !important;
  font-weight: 600;
  border-radius: .4em;
  padding: 0em;
  margin-top: .3em;
  min-width: 20em;
  z-index: 1000;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

.is-success-icon {
  float: left;
  height: 20px;
  color: white;
  margin-right: 9px;
  opacity: .6;
}

.is-dash-back-button-icon {
  height: 25px;
  color: #1d2531 !important;
  opacity: .25;
  display: inline-block;
  margin: 0px;
}

.is-dash-back-btn {
  background-color: none;
  border: 1px solid #dfe8ef !important;
  font-size: 16px;
  font-weight: bold;
  padding: .5em .75em .5em .75em;
  border-radius: .25em;
  color: #1D2531;
  cursor: pointer;
  margin-right: 12px;
}

.is-bulk-journalist-pagination-button {
  background-color: none !important;
  color: #4A4A4A;
  font-size: 1em;
  font-weight: bold;
  border: none;
  float: left !important;
  /*margin-left: 4em;*/
  border-radius: .4em;
  text-align: center;
  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
}

.bulk-journo-name {
  font-weight: bold;
}

.bulk-journalist-card {
  background-color: white;
  border-top: 1px solid #dfe8ef;
  border-right: 1px solid #dfe8ef;
  border-left: 1px solid #dfe8ef;
  border-bottom: 1px solid #ffffff;
  width: 100%;
  margin-bottom: 0px;
  text-align: left;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

/* end bulk dash styling */

/* influencer styling */

.followers-number {
  font-weight: bold;
  margin-bottom: 0px;
  padding-bottom: 4px;
}

.followers {
  background-color: #f5f8fb;
  display: flex;
  padding: 6px 8px 2px 8px;
  margin: 0px 10px 10px 0px;
  font-size: 13px;
  border-radius: 7px;
}

.twitter-verified-badge {
  height: 20px;
  margin-left: 8px;
  margin-top: 2px;
}

/* end of influencer styling */

/* new list styling */

.is-create-list-icon {
  height: 25px;
  margin-right: 7px;
}

.new-create-list-button {
  padding: 6px 10px 6px 6px;
  background-image: linear-gradient(0deg,#354052 0,#4A5971 100%);
  border-radius: .25em;
  color: white;
  font-weight: bold;
  display: flex;
}

.new-create-list-button:hover {
  text-decoration: none;
}

.new-create-list-button-text {
  padding-top: 2.5px;
  margin-bottom: 0px;
}

.is-form {
  margin: auto;
  text-align: center;
  padding: 2em;
  margin-left: 116px;
  margin-right: 116px;
  max-width: 25em;
  border-radius: .4em;
  margin-top: 73px;
  height: 20em !important;
  background-color: white;
  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
}

.is-delete-button-row {
  margin: auto;
  text-align: center;
  padding: 2em;
}

/* end of new list styling */

/* tweet dashboard styling */

.tweet-card-content {
  font-size: 18px;
  margin: 0px;
  padding: 18px 18px 10px 18px;
  font-weight: bold;
  color: #1d2531;
}

/* end of tweet dashboard styling */

/* new dashnav v3 */

.tooltip.is-tooltip-primary::before {
  color: black;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.5);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.5);
  border-radius: 4px;
  font-size: 18px;
}

.dashnav-v3-logo-icon {
  max-width: 36px;
  max-height:  36px;
  height: auto;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.5);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.5);
}

.active-nav-selection {
  background-color: black !important;
}

.subnav-title-wrapper {
  display: flex;
}

.subnav-title {
  color: white;
  font-weight: bold;
  float: left;
  font-size: 18px;
  padding-top: 1px;
  padding-bottom: 10px;
  margin-bottom: 8px;
  padding-left: 18px;
  border-bottom: solid 1px #181f28;
  width: 180px;
  height: 38px;
}

.is-dashnav-v3-title {
  color: white;
  font-weight: bold;
  font-size: 20px;
  padding-top: .325em;
  margin-bottom: 0em;
  margin-left: 15px;
}

.dashnav-v3-icon {

  max-width: 36px;
  max-height:  36px;
  height: auto;
}

.dashnav-v4-icon-group {
  display: flex;
  padding: 10px 18px 10px 18px;
  margin: 0em;
}

.dashnav-v4-icon-group:hover {
  display: flex;
  padding: 10px 18px 10px 18px;
  background-color: rgba(0,0,0,.25);
}

.subdashnav-v3 {
  background-color: #1d2531;
  float: left;
  top: 0;
  bottom: 0;
  left: 0;
  width: 160px;
  position: fixed;
  border-right: solid 1px #0d1216;
  margin-left: 72px;
  padding-top: 84px;
}

.dashnav-v3 {
  background-color: #141b21;
  float: left;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 72px;
  position: fixed;
  border-right: solid 1px #0d1216;
}

.dashnav-v3-logo-group {
  display: flex;
  padding: 18px;
  background-color: #141b21;
  border-bottom: solid 1px #0d1216;
  border-right: solid 1px #0d1216;
  width: 232px;
  z-index: 10001;
}

.dashnav-v3-logo-group-wrapper {
  margin-bottom: 8px;
}

.dash-upper-nav-v3 {
  height: 73px;
  background-color: white;
  padding: 1.35em 1em 1em 3em;
  color: black;
  margin-bottom: 0em;
  display: flex;
  border-bottom: 1px solid #E3E8EF;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.dash-upper-nav-icon {
  width: 1em;
}

/* end of new dashnav v3 */

.form-button-below {
  font-size: 12px;
  opacity: .5;
  margin-top: 18px;
  margin-bottom: 0px;
}

.dashnav-beta-tag {
  font-size: 10px;
  font-weight: bold;
  color: white;
  padding: 2px;
  padding-right: 2.5px;
  padding-left: 2.5px;
  background-color: #003dff;
  margin-left: 5px;
  margin-top: 0px;
  margin-bottom: 0em;
  border-radius: .4em;
  height: 17px;
}

/* broadcast styling */

.broadcast-card-salutation {
  border: solid 1px #f5f8fb;
  display: flex;
  padding: 3px 4px 0px 5px;
  margin: 0px 10px 0px 0px;
  height: 25px;
  color: #576472;
  font-size: 13px;
  border-radius: 4px;
  font-weight: bold;
}

.press-hunt-score-number {
  font-weight: bold;
  color: white;
  margin-bottom: 0px;
  padding-bottom: 4px;
}

.press-hunt-score {
  background-color: #1d2531;
  display: flex;
  padding: 6px 8px 2px 8px;
  margin: 0px 10px 10px 0px;
  font-size: 13px;
  border-radius: 4px;
}

/* end broadcast styling */

/* updated dashnav */

.dashnav-v3-icon-group {
  display: flex;
  padding: .5em 0em .5em 1em;
  margin: 0em;
}

.dashnav-v3-icon-group:hover {
  display: flex;
  padding: .5em 0em .5em 1em;
  background-color: rgba(0,0,0,.15);
}

/* end of updated dashnav */
/* campaign styling */

.active-domains-text {
  font-weight: bold;
  margin-bottom: 0px;
}

.campaign-form-domain-item {
  padding-left: 5px;
  font-style: italic;
  margin-bottom: 0px;
}

.campaign-form-domain-input-div {
  margin-top: 18px;
  display: flex;
}

.form-para-icon {
  height: 20px;
  float: left;
  margin-right: 5px;
}

.sequence-card-footer-button-standard-w-border {
  border: none;
  font-size: 1em;
  font-weight: bolder;
  display: flex;
  border: 1px solid #dfe8ef !important;
  border-radius: .4em;
  padding: 10px;
  background-color: white !important;
}

.is-dash-left-campaign-card {
  background-color: none;
  color: #1d2531;
  padding: 1.5em 1.5em 1.5em 1.5em;
  margin: 0em 1em 0em 0em !important;
  border: solid 1px #dfe8ef;
  border-radius: .4em;
  float: left;
  display: inline-block !important;
}

.dropdown-content-campaign {
  background-color: white;
  border: 1px solid #dfe8ef !important;
  color: white !important;
  font-weight: 600;
  border-radius: .4em;
  padding: 0em;
  margin-top: .3em;
  min-width: 20em;
  z-index: 1000;

  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

/* end campaign styling */

/* updated pr request styling for haro cards */

.journalist-card-tag-wrapper-req {
  display: inline-block;
  height: 100%;
  padding-bottom: 9px;
}

.journalist-card-tag-variant-white-border {
  background-color: #f5f8fb;
  display: flex;
  padding: 6px 8px 2px 8px;
  margin: 0px 10px 10px 0px;
  color: #1d2531;
  font-size: 13px;
  border-radius: 4px;
  font-weight: bold;
  border: 1px solid #dfe8ef;
}

.req-card-title {
  font-weight: bold;
  font-size: 18px;
  color: #1d2531;
  padding: 18px 18px 0px 18px;
  margin-bottom: 0px;
}

.req-card-deadline {
  color: #1d2531;
  padding: 8px 18px 18px 18px;
  margin-bottom: 0px;
  font-size: 15px;
}

.req-card-require {
  color: #1d2531;
  padding: 10px 18px 10px 18px;
  margin-bottom: 0px;
}

/* end updated pr req styling */

/* article database styling */

.article-card-trial {
  background-color: #f5f8fb;
  border-radius: .4em;
  border: 1px solid #dfe8ef;
  width: 100%;
  margin-bottom: 2em;
  text-align: left;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

.trial-search-wrapper-articles {
  display: inline-block !important;
  margin: auto !important;
  background-color: #f5f8fb;
  max-width: 685px;
  border-radius: .4em;
  padding: 1em 1em 1.5em 1em;
  float: none;
  border: 1px solid rgba(227,232,239,.3);
  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
}

.article-card-sub-footer-button {
  padding: 10px 18px 10px 18px;
  border-right: 1px solid #dfe8ef;
  height: 100%;
}

.article-card-content-wrapper {
  margin-top: 8px;
  background-color: white;
  border-radius: .4em;
  border: 1px solid #dfe8ef;
  margin-bottom: 18px;
}

.article-card-sub-footer {
  display: flex;
  border-top: 1px solid #dfe8ef;
}

.article-card-source {
  text-transform: uppercase;
  font-size: 12px;
  color: #5e6e77;
  font-weight: bold;
  margin: 0px;
  padding: 18px 18px 5px 18px;
}

.article-card-title {
  font-weight: bold;
  font-size: 18px;
  margin: 0px;
  padding: 0px 18px 10px 18px;
  color: black;
}

.article-card-content {
  font-size: 16px;
  margin: 0px;
  padding: 0px 18px 10px 18px;
}

.article-card-tag {
    display: flex;
    padding: 6px 8px 2px 8px;
    margin: 0px 10px 10px 0px;
    color: #1d2531;
    font-size: 13px;
    border-radius: 4px;
    font-weight: bold;
    border: solid 1px #dfe8ef;
  }

/* end of article database styling */

/* new pricing shit */

.is-agency-pricing-div {
  max-width: 850px;
  margin: auto;
}

.is-boost-pricing-card {
  max-width: 500px !important;
  border-radius: .4em;
  margin: auto;
  margin-bottom: 50px;
  border: 1px solid rgba(41,49,62,.08);
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05) !important;
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05) !important;
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05) !important;
}
.is-boost-pricing-card:hover {
  -webkit-box-shadow: 0px 7px 15px 0px rgba(0,0,0,0.075) !important;
  -moz-box-shadow: 0px 7px 15px 0px rgba(0,0,0,0.075) !important;
  box-shadow: 0px 7px 15px 0px rgba(0,0,0,0.075) !important;
}

/* end of new pricing shit */

/* new create seq & domain forms */

.is-dash-left-sequence-card-top {
  background-color: none;
  color: #1d2531;
  padding: 1.5em 1.5em 0em 1.5em;
  margin: 0em 1em 0em 0em !important;
  border: solid 1px #dfe8ef;
  border-top-left-radius: .4em;
  border-top-right-radius: .4em;
  float: left;
  display: inline-block !important;
}

.is-dash-left-sequence-card-bottom {
  background-color: none;
  color: #1d2531;
  padding: 1.5em 1.5em 0em 1.5em;
  margin: 0em 1em 0em 0em !important;
  border-left: solid 1px #dfe8ef;
  border-right: solid 1px #dfe8ef;
  border-bottom: solid 1px #dfe8ef;
  border-bottom-left-radius: .4em;
  border-bottom-right-radius: .4em;
  float: left;
  display: inline-block !important;
}

.stats-card-content {
  padding: 18px 18px 8px 18px;
  color: #1d2531;
}

.footer-button-onclick-styling:active {
  opacity: .5 !important;
}

.domain-unverified-text {
  color: #ef2942;
}

.is-strong {
  font-weight: bold;
  color: #1D2531;
}

.seq-form-required {
  color: #ef2942;
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  padding-bottom: 3px;
}

.active-domain-text {
  color: #28b53b;
}

.domain-auth-text {
  color: #28b53b;
}

.domain-card-content {

}

.domain-txt-record-item {
  margin: 18px;
  padding: 18px;
  border: solid 1px rgb(223,232,239);
  background-color: #f5f8fb;
  border-radius: .4em;
}

.domain-txt-record-item-last {
  margin: 18px;
  padding: 18px;
  border: solid 1px rgb(223,232,239);
  background-color: #f5f8fb;
  border-radius: .4em;
}

.txt-record-first {
  border-bottom: solid 1px rgba(223,232,239,.5);
  padding: 10px;
  margin: 0px;
  background-color: white;
  border-top-left-radius: .4em;
  border-top-right-radius: .4em;
}

.txt-record-value {
  padding: 10px;
  margin: 0px;
  background-color: white;
  border-bottom-left-radius: .4em;
  border-bottom-right-radius: .4em;
}

.txt-record-label {
  font-weight: bold;
  margin-bottom: 10px;
}

.txt-rec-card-line {
  margin: 0px;
  display: flex;
}

.txt-record-text {
  word-break: break-all;

  border: solid 1px rgba(223,232,239,.5);
  border-radius: .4em;
  color: #1D2531;
}

.basic-multi-select {
  margin-top: 18px;
}

.control {
  font-size: 14px;
}

.select__control--is-focused {
  color: red;
  padding: 3px 0px 3px 14px;
}

.css-1wa3eu0-placeholder {
  color: #1d2531 !important;
  opacity: .5;
}

.css-yk16xz-control {
  border-color: #dfe8ef !important;
  padding: 3px 0px 3px 14px;
}

.css-1hwfws3 {
  padding: 0px !important;
}

.left-seq-form-column {
  margin: 3em 0em 0em 3em;
  max-width: 348px;
  color: #1d2531;
  padding: 1.5em 1.5em 0em 1.5em;
  margin: 0em 1em 1em 0em !important;
  border: solid 1px #dfe8ef;
  border-radius: .4em;
}

.is-sequence-form {
  padding: 0em;
  border-radius: .4em;
  border: solid 1px #dfe8ef;
}

/* end new create seq form */

/* journo request styling */

.req-card-twitter-entities {
  border: solid 1px #dfe8ef;
  display: flex;
  padding: 6px 10px 0px 10px;
  margin: 0px 10px 12px 0px;
  height: 31px;
  color: #1d2531;
  font-size: 13px;
  border-radius: 4px;
  font-style: italic;
  font-weight: bold;
}

.new-tag-landing-seq {
  background-color: #1d2531;
  max-width: 175px;
  color: white;
  margin: auto auto 1.2em auto;

  padding: 6px 10px 6px 0px;
  border-radius: .3em;
  font-size: 15px !important;
  font-weight: 800 !important;
}

.new-tag-landing-span {
  background-color: #F01D65;
  color: white;
  margin-bottom: 1.2em;
  padding: 3px 6px 3px 6px;
  border-radius: .3em;
  margin: 5px 5px 5px 4px;
  font-size: 15px !important;
  font-weight: 800 !important;
}

.new-tag-landing {
  background-color: #1d2531;

  max-width: 165px;
  color: white;
  margin: auto auto 1.2em auto;
  padding: 6px 6px 6px 0px;
  border-radius: .3em;
  font-size: 15px !important;
  font-weight: 800 !important;

}

.dashnav-new-tag {
  font-size: 10px;
  font-weight: bold;
  color: white;
  padding: 2px;
  padding-right: 2.5px;
  background-color: #F01D65;
  margin-left: 7px;
  margin-top: 0px;
  margin-bottom: 0em;
  border-radius: .4em;
  height: 17px;
}

.message-on-twitter {
  color: #003dff;
  margin: 0;
}

.req-footer-icon-right {
  height: 21px;
  padding-left: 5px;
}

.req-floating-cta {
  position: fixed;
  z-index: 10000;
  background-color: #F01D65;
  padding: 12px;
  font-weight: bold;
  color: white;
  border-radius: .4em;
  max-width: 400px;
  bottom: 18px;
  left: 197px;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

.free-journo-request-card {
  border-radius: .4em;
  width: 100%;
  margin-bottom: 2em;
  text-align: left;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

.free-req-card-footer-cta {
  background-color: #f5f8fb;
  margin: 0;
  border-bottom-left-radius: .36em;
  border-bottom-right-radius: .36em;
  padding: 14px 18px 14px 18px;
  border-top: 1px solid #dfe8ef;
  font-weight: bold;
  display: flex;
  color: #0060FF;
}

.journo-request-card {
  background-color: #f5f8fb;
  border-radius: .4em;
  border: 1px solid #dfe8ef;
  width: 100%;
  margin-bottom: 2em;
  text-align: left;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

.journo-request-card-content {
  background-color: white;
  border-radius: .4em;
  border: 1px solid #dfe8ef;
  margin-bottom: 18px;
}

.req-card-header {
  border-bottom: 1px solid #dfe8ef;
  padding: 18px;
  font-weight: bold;
  color: #1d2531;
  font-size: 18px;
  display: flex;
  margin-bottom: 0;
}

.free-req-card-query {
  padding: 18px 18px 8px 18px;
  color: black;
  margin-bottom: 0;
  font-size: 18px;
}

.req-card-query {
  padding: 10px 18px 8px 18px;
  color: #1d2531;
  margin-bottom: 0;
  font-size: 18px;
}

.req-card-date {
  color: black;
  margin-bottom: 0;
  font-size: 14px;
  color: #1d2531;
  opacity: .5;
  padding: 0px 18px 18px 18px;
}

.req-card-details {
  padding: 14px 18px 14px 18px;
  margin-bottom: 0;
  border-top: 1px solid #dfe8ef;
  display: flex;
  font-weight: bold;
  color: #1d2531;
  font-size: 18px;
}

.sequence-card-footer-button-blue-text {
  color: #003dff;
}

.req-card-url-tag {
  border: solid 1px #dfe8ef;
  display: flex;
  padding: 6px 10px 0px 10px;
  margin: -3px 10px 0px 12px;
  height: 31px;
  color: #1d2531;
  font-size: 13px;
  border-radius: 4px;
  font-style: italic;
  font-weight: bold;
}

/* end of journo request styling */

/* updated trial search on landing pages */

.trial-search-wrapper-new {
  display: inline-block !important;
  margin: auto !important;
  background-color: #f5f8fb;
  max-width: 700px;
  border-radius: .4em;
  border: 1px solid #dfe8ef;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

.trial-search-results-container {
  margin: auto !important;
}

.trial-search-tabs {
  padding: 18px;
  border-bottom: 1px solid #dfe8ef;
}

/* end updated trial search on landing pages */

/* Updated journalist UI */

.journalist-card-country-flag {
  height: 15px;
  max-width: 25px;
  margin-right: 10px;
  margin-top: 4px;
  border-radius: 2.5px;
  -webkit-box-shadow: 0 3px 2px rgba(29,37,49,.05);
  -moz-box-shadow: 0 3px 2px rgba(29,37,49,.05);
  box-shadow: 0 3px 2px rgba(29,37,49,.05);
}

.ais-RefinementList-showMore {
  font-size: 12px;
  padding: 6px 9px;
  background-color: white;
  border: solid 1px #dfe8ef;
  margin: 10px 0px 10px 18px;
  color: #4d5a60;
  border-radius: 7px;
  font-weight: 600;
  box-shadow: rgba(191, 191, 191, 0.15) 0px 2px 2px 1px;
  cursor: pointer;
}

.ais-RefinementList-showMore:hover {
  color: #1d2531;
}

.is-dash-left-action-card-new {
  background-color: #1D2531;
  color: white;
  padding: 18px;
  width: 348px;
  border-radius: .4em;
  float: left;
  display: inline-block !important;

  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
}

.search-left-pag-column {
  width: 384px;
}

.is-journalist-pagination-button {
  background-color: none !important;
  color: #4A4A4A;
  font-size: 1em;
  font-weight: bold;
  border: none;

  /*margin-left: 4em;*/
  border-radius: .4em;
  text-align: center;
  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
}

.journalist-search-top-buttons {
  display: flex;
  float: right;
  margin-bottom: 50px;
}

.search-columns {
  display: flex;
}

.search-filter-column {
  margin-right: 36px;
  max-width: 348px;
}

.search-card-column {

}

.journalist-search-add-all-div {
  margin-left: 0px;
  margin-top: 0px !important;
}

/* End updated journalist UI */

/* New journalist filters */

.is-journalist-filter-card {
  background-color: transparent;
  color: #1d2531;
  border: 1px solid #dfe8ef;
  border-radius: .4em;
  margin: 0px 18px 18px 0px;
  width: 350px;
}

.is-filter-section {
  padding: 18px 18px 12.4px 18px;
  border-bottom: 1px solid #dfe8ef;
}

.search-filters-title {
  font-size: 12px;
  font-weight: medium;
  color: #a8b8c1;
  margin: 0px 0px 10px 18px;
}

.journalist-filter-refinement-list {
  font-weight: normal;
}

/* End new journalist filters */

/* New journalist card styling */

/*.journalist-card-dash {
  width: 650px !important;
}
*/
.journalist-card {
  background-color: white;
  border-radius: .4em;
  border: 1px solid #dfe8ef;
  width: 100%;
  margin-bottom: 2em;
  text-align: left;
  box-shadow: rgba(10,40,91,.035) 0px 2px 5px, rgba(10,40,91,.035) 0px 5px 12px;
}

.journalist-card-footer-button {
  padding: 14px 18px 14px 18px;
  border-right: 1px solid #dfe8ef;
  height: 100%;
}

.journalist-card-opt-header {
  border-bottom: 1px solid #dfe8ef;
  background-color: #f5f8fb;
  border-top-left-radius: .355em;
  border-top-right-radius: .355em;
  display: flex;
}

.journalist-card-header-tab {
  font-weight: medium;
  padding: 12px 18px 12px 18px;
  border-right: 1px solid #dfe8ef;
  display: flex;
  color: #1d2531;
  height: 100%;
}

.journalist-card-header-tab-icon {
  height: 16px;
  padding-right: 10px;
  margin-top: 4px;
}

.journalist-card-url-link {
  height: 16px;
  width: 16px;
  margin-right: 6px;
  opacity: .5;
}

.journalist-card-content {
  padding: 18px 18px 6px 18px;
  color: #1d2531;
}

.journalist-card-content-header {
  display: flex;
  margin-bottom: 0px;
}

.journalist-card-picture {
  border-radius: 100px;
  height: 65px;
  width: 65px;
  position: absolute;
  z-index: 2;
}

.journalist-card-name-title {
  display: block;
  padding-top: 8px;
  padding-left: 18px;
  min-height: 70px;
  margin-left: 65px;
}

.journalist-card-name {
  color: #1d2531;
  font-weight: bolder;
  font-size: 21px;
  margin: 0;
  padding-bottom: 3px;
}

.journalist-card-type-outlet {
  color: #1d2531;
}

.journalist-card-kind {
  font-weight: bold;
}

.journalist-card-outlet {
  font-weight: bold;
}

.journalist-card-tab-text {
  margin-bottom: 0;
}

.journalist-card-tab-text-link {
  color: #1d2531;
  font-weight: bolder;
}

.journalist-card-bio {

}

.new-add-to-new-list-text {
  color: #003dff;
  font-weight: bold;
}

.new-add-to-list-text {
  color: #003dff;
  font-weight: bold;
  padding-top: 2px;
}

.add-to-list-icon-right {
  height: 21px;
  padding-left: 10px;
}

.journalist-card-icon-standard {
  height: 21px;
  padding-right: 10px;
}

.journalist-card-icon-alone {
  height: 21px;

}

.journalist-card-tags-full {
  margin: 18px 18px 6px 18px;
  border-radius: .4em;
}

.journalist-card-tags-top-wrapper {


  border-top-left-radius: .4em;
  border-top-right-radius: .4em;
}

.journalist-card-tag-wrapper {
  display: inline-block;
  height: 100%;
}

.journalist-card-tag {
  display: flex;
  margin: 0px 10px 10px 0px;
  color: #1d2531;
  font-size: 15px;
}

.journalist-card-tag-variant {
  background-color: #f5f8fb;
  display: flex;
  padding: 6px 8px 2px 8px;
  margin: 0px 10px 10px 0px;
  color: #1d2531;
  font-size: 13px;
  border-radius: 7px;
  font-weight: bold;
}

.journalist-card-tag-icon {
  height: 18px;
  padding-right: 6px;
}

.journalist-card-tag-text {
  padding-bottom: 4px;
  margin: 0;
}

.journalist-card-tags-bottom-wrapper {
  padding: 10px 10px 0px 10px;
  border-bottom-left-radius: .4em;
  border-bottom-right-radius: .4em;
}

.journalist-card-header-text {
  margin: 0;
}

.journalist-card-name-and-link {
  display: flex;
}

.journalist-card-url-tag {
  border: solid 1px #f5f8fb;
  display: flex;
  padding: 6px 10px 0px 10px;
  margin: -3px 10px 0px 12px;
  height: 31px;
  color: #1d2531;
  font-size: 13px;
  border-radius: 4px;
  font-style: italic;
  font-weight: bold;
}

.journalist-card-url-link-text {
  color: #576472;
}

.journalist-card-media-outlet-tag {
  border: solid 1px #f5f8fb;
  display: flex;
  padding: 6px 10px 0px 10px;
  height: 31px;
  color: #1d2531;
  max-width: 94px;
  font-size: 13px;
  border-radius: 4px;
  font-style: italic;
  font-weight: bold;
}

.journalist-card-phone-text {
  padding-top: 3px;
  margin-bottom: 0px;
}

.journalist-card-country-text {
  padding-top: 3px;
  margin-bottom: 0px;
}

/* End new journalist card styling */

/* Sequence styling */



.is-sequence-form-modal {
  z-index: 1000;
}

.is-sequence-card {
  background-color: white;
  border-radius: .4em;
  border: 1px solid #dfe8ef;
  /*max-width: 650px;*/
  margin-bottom: 2em;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

.is-sequence-card-empty {
  background-color: none;
  border-radius: .4em;
  border: 1px solid #dfe8ef;
  margin-bottom: 2em;
}

.sequence-card-header {
  border-bottom: 1px solid #dfe8ef;
  padding: 18px;
}

.sequence-card-icon-header {
  height: 21px;
  padding-right: 10px;
  margin-top: 2px;
}

.sequence-card-header-icon-and-title {
  display: flex;
}

.sequence-card-header-title {
  margin-bottom: 0em;

  font-size: 1.2em;
  font-weight: bolder;
  color: black;
}

.sequence-card-content {
  padding: 18px 18px 0px 18px;
  color: #1d2531;
}

.sequence-card-email {
  margin: 18px;
  border: 1px solid #dfe8ef;
  border-radius: 5px;
  /* background-color: #fafcfd;*/
}

.sequence-card-email-subject {
  padding: 10px;
  border-bottom: 1px solid #dfe8ef;
  display: flex;
}

.sequence-card-email-body {
  padding: 10px;
  display: flex;
}

.sequence-card-email-label-text {
  font-size: 12px;
  font-weight: bolder;
  color: #a8b8c1;
  opacity: 1;
  padding-top: 3px;
  padding-right: 10px;
  margin-bottom: 0px;
}

.sequence-card-email-text {
  margin: 0em;
  padding: 0em;
  color: #1d2531;
  font-size: 15px;
}

.sequence-card-email-subject-text {
  margin: 0em;
  padding: 0em;
  color: #1d2531;
  font-size: 15px;
  font-weight: bold;
}

.sequence-card-line {
  margin-bottom: 10px;
  display: flex;
}

.sequence-card-footer {
  display: flex;
  border-top: 1px solid #dfe8ef;
}

.sequence-card-footer-button {
  padding: 14px 18px 14px 18px;
  border-right: 1px solid #dfe8ef;
}

.sequence-card-footer-button-activate-text {
  font-weight: bolder;
  color: #1d2531;
}

.sequence-card-footer-button-standard-text {
  color: #1d2531;
  font-weight: bolder;
  padding-top: 2px;
}

.sequence-card-footer-button-standard:hover {
  cursor: pointer;
}

.sequence-card-footer-button-archive {
  color: #ef2942;
  border: none;
  font-size: 1em;
  font-weight: bolder;
  display: flex;
  padding: 0em;
}

.sequence-card-footer-button-standard {
  border: none;
  font-size: 1em;
  font-weight: bolder;
  display: flex;
  padding: 0em;
  background-color: transparent !important;

}

.sequence-card-icon-archive {
  height: 21px;
  padding-right: 6px;
}

.sequence-card-icon-standard {
  height: 21px;
  padding-right: 6px;
}

.create-sequence-button-icon {
  height: 16px;
  padding-right: 6px;
  margin-top: 4px;
}

.create-sequence-button-text {
  padding-top: 3px;
}

.sequence-card-content-icon {
  height: 26px;
  padding-right: 10px;
}

.sequence-card-content-text {
  padding-top: 4px;
  margin-bottom: 0px;
}

.sequence-card-footer-button-archive:hover {
  cursor:pointer;
}

.sequence-card-footer-button-archive-text {
  padding-top: 2px;
}

.is-sequence-page {
  display: block;
}

.sequence-page-buttons {
  display: flex;
  float: right;
}

.sequence-card-list {
  margin-top: 75px;
}

.create-sequence-button {
  display: flex;
  background-image: linear-gradient(0deg,#067ED8 0,#1791ED 100%);
  color: white !important;
  font-size: 1em;
  padding: .4em .75em .6em .75em;
  margin-bottom: 0em;
  margin-left: 10px;
  line-height: normal;
  border-radius: .25em;
  font-weight: bold;
  text-decoration: none !important;
  border: 1px solid #f5f8fb;
  cursor: pointer;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
}

.auth-domain-button {
  display: flex;
  background-color: white;
  color: #1d2531;
  font-size: 1em;
  padding: .4em .75em .6em .75em;
  margin-bottom: 0em;
  line-height: normal;
  border-radius: .25em;
  font-weight: bold;
  text-decoration: none !important;
  border: 1px solid #dfe8ef;
  cursor: pointer;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
}

.main-dash-line-separator-sequence {
  border-top: 1px solid #dfe8ef;
  opacity: .5;
  width: 100%;
  padding-bottom: 2em;
  margin-top: 1em;
  max-width: 650px;
}

.is-dash-left-sequence-card {
  background-color: none;
  color: #1d2531;
  padding: 1.5em 1.5em 0em 1.5em;
  margin: 0em 1em 1em 0em !important;
  border: solid 1px #dfe8ef;
  border-radius: .4em;
  float: left;
  display: inline-block !important;
}

.is-dash-left-sequence-card-title {
  font-size: 21px;
  color: #1d2531;
  font-weight: bolder;
  padding-bottom: .3em;
}

.is-action-card-para {
  font-size: 1;
  font-weight: normal;
  line-height: normal;
  margin: 0em;
  padding-bottom: 1.6em;
  opacity: .8;
}

.sequence-form-q {
  padding: 32px 32px 32px 32px;
  border-bottom: solid 1px #dfe8ef;
}

.sequence-form-last-q {
  padding: 32px 32px 32px 32px;
}

.is-sequence-form-button {
  display: flex;
  background-image: linear-gradient(0deg,#067ED8 0,#1791ED 100%);
  color: white !important;
  font-size: 1em;
  padding: .4em .75em .6em .75em;
  margin-bottom: 0em;
  border-radius: .25em;
  font-weight: bold;
  text-decoration: none !important;
  border: none;
  cursor: pointer;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
}

.sequence-form-input {
  margin-top: 18px;
  padding: 14px;
  border-radius: .4em;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  background-color: white !important;
  border: solid 1px #dfe8ef !important;
}

.sequence-form-input::placeholder {
  font-weight: 600;
  font-size: 14px;
  color: #1d2531;
  opacity: .5;
}

/*.sequence-form-input:active {
  font-weight: 600;
  font-size: 14px;
  color: #1d2531;
  opacity: .25;
}*/

.rich-text-editor {
  min-height: 380px;
  margin-top: 18px;
  padding: 5px;
  border-radius: 7px;
  width: 100%;
}

.sequence-form-label {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 1em !important;
  margin-bottom: 0em;
}

.sequence-form-sublabel {
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 0em;
}

.sequence-form-button-wrapper {
  display: flex;
  padding: 0px 32px 32px 32px;
  margin-bottom: 0px;
}

.sequence-form-submit-msg {
  margin: 0em;
  margin-bottom: 0em !important;
  padding-top: 7px;
  margin-left: 18px;
}

.sequence-form-submit-msg-text {
  color: #1d2531;
  font-weight: bold;
  margin-bottom: 0em;
}

.is-checkradio[type=checkbox]+label, .is-checkradio[type=radio]+label {
  margin: 0em;
  padding-right: 1.4em;
  font-weight: 600;
}

.is-checkradio[type=checkbox]:hover:not([disabled])+label::before, .is-checkradio[type=checkbox]:hover:not([disabled])+label:before, .is-checkradio[type=radio]:hover:not([disabled])+label::before, .is-checkradio[type=radio]:hover:not([disabled])+label:before {
  border-color: #a4b3ba !important;
}

.is-checkradio[type=radio].is-info:hover:not([disabled])+label::before, .is-checkradio[type=radio].is-info:hover:not([disabled])+label:before {
  border-color: #003dff;
}

.is-checkradio[type=radio]+label::after, .is-checkradio[type=radio]+label:after {
  background-color: #003dff;
}

.is-checkradio[type=checkbox]:checked+label::before, .is-checkradio[type=checkbox]:checked+label:before, .is-checkradio[type=radio]:checked+label::before, .is-checkradio[type=radio]:checked+label:before {
  border: 2px solid #dfe8ef;
}

.switch[type=checkbox].is-info:checked+label::before, .switch[type=checkbox].is-info:checked+label:before {
  background-color: #003dff;
}

.switch[type=checkbox]+label::before, .switch[type=checkbox]+label:before {
  background-color: #d1dde5;
}

.switch[type=checkbox]+label::after, .switch[type=checkbox]+label:after {
  background-color: white;
}

/* End sequence styling */

.navbar-burger, .burger {
  color: white !important;
}

.navbar-item.logo{
  width: 23em;
}

.navbar-item img{
  max-height: 3em !important;
}

.navbar-brand {
  width: 20em;
}

.navbar-menu {
  width: 20em;
}

.ais-Pagination-link:hover {
  color: #1d2531;
}

.ais-Pagination-link--selected {
  color: #5e6e77;
  padding: 0px 5px;
  margin: 0px;
  border: solid 1px #eff3f7;
  border-radius: 10px;
}

.ais-Pagination-link--selected:hover {
  color: #5e6e77;
  cursor: auto;
}

.navbar-menu.is-active {
    animation: navAnim .2s ease-in-out;
}

@keyframes navAnim {
  0% {
    display: none;
    opacity: 0;
    height: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  25% {
    opacity: 0.25;
    height: 25%;
  }
  50% {
    opacity: 0.5;
    height: 50%;
  }
  75% {
    opacity: 0.75;
    height: 75%;
  }
  100% {
    opacity: 1;
    height: 100%;
  }
}

.is-grouped {
  padding-top: 1.2em;
}

.is-logo {
  height: 100em;
  float: left;
  padding-right: .75em;
  display: block;
}

.is-dash-logo {
  height: 100em;
  float: left;
  padding-right: .75em;
  display: block;
}

.is-logo-word {
  float: right;
  padding-top: .49em;
}

.by-scribble {
  opacity: .5;
}

.times {
  background-color: #457fff;
  color: white;
  float: right;
  margin-left: 1.2em;
  padding: .2em .4em .2em .4em;
  border-radius: .3em;
  font-size: 14px !important;
  font-weight: 700;
}

.new {
  background-color: rgba(255,255,255,.1);
  color: white;
  float: right;
  margin-right: .8em;
  padding: .2em .4em .2em .4em;
  border-radius: .3em;
  font-size: 12px !important;
  font-weight: 800 !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

.is-hero-title-center {
  line-height: 1.275em !important;
  margin: auto !important;
  padding-bottom: .5em;
}

.is-hero-title {
  line-height: 1.275em !important;
  padding-bottom: .5em;
  text-align: left !important;
}

.is-hero-para {
  padding-top: 1em;
  max-width: 30em;
  margin: auto;
}
.is-hero-sub {
  padding-top: 1.5em;
  max-width: 15em;
  margin: auto;
  opacity: .5;
}
.hero-div-width {
  max-width: 40em;
  margin: auto;
}

.section-card {
  background-color: white !important;
  border-radius: 1em;
  margin-top: 2em !important;
  padding: 2.5em 2.5em 1.5em 2.5em;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

.section-card-title {
  padding-bottom: .25em;
}

.is-section-card-para {
  padding-bottom: 1em !important;
}

.is-hiw-card {
  background-color: white;
  padding: 2em !important;
  margin: 1em;
  border-radius: 1em;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

.is-sample-card:hover {
  -webkit-box-shadow: 0px 7px 15px 0px rgba(0,0,0,0.075) !important;
  -moz-box-shadow: 0px 7px 15px 0px rgba(0,0,0,0.075) !important;
  box-shadow: 0px 7px 15px 0px rgba(0,0,0,0.075) !important;
}

.clear-section-card {
  background-color: transparent !important;
  padding: 2.5em 2.5em 1.5em 2.5em;
}

.clear-section-card-writing-samples {
  background-color: transparent !important;
  padding: 2.5em 0em 1.5em 0em;
}

.clear-pricing-section-card {
  text-align: center;

  margin-top: auto;
  margin-bottom: auto;
}

.no-styling,.no-styling:hover {
  color: inherit;
}

.section-title {
  padding-bottom: .25em;
  border-bottom: 3px solid #F0F0F0;
  display: inline-block;
  margin-bottom: 2em !important;
  font-weight: 700 !important;
}
.is-para {
  padding-right: 8em !important;
  padding-bottom: 3em !important;
}
.is-para-case-study {
  padding: 2.1em 0em 3em 2em !important;
}
.is-testimonial-col {
  padding: 0em 1.5em 3em 1.5em !important;
}
.is-testimonial {
  border-radius: .3em !important;
  border: 1px solid rgba(41,49,62,.08);
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05) !important;
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05) !important;
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05) !important;
}

.is-email-card {
  background-color: white;
  padding: 2em !important;
  margin: 2em auto 3em auto;
  max-width: 50em;
  border-radius: 1em;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

.feedback-subtitle {
  margin: 3em 0em .25em 0em !important;
  opacity: .4;
  font-weight: 600;
}

.not-displayed {
  display: none;
}

.para-title {
  font-weight: 600 !important;
  padding-bottom: .5em !important;
  margin-bottom: 0em !important;
  line-height: 1.275em !important;
}

.is-hiw-para {
  font-weight: 400 !important;
  padding-top: 1em !important;
  margin-bottom: 0em !important;
  line-height: 1.275em !important;
}

.is-feature {
  font-weight: 700 !important;
  padding: 1em !important;
  margin-bottom: 0em !important;
  line-height: 1.3em !important;
}

p {
  line-height: 1.325em !important;
}

.case-study-image {
  border-radius: 1em;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

.testimonial-profile-pic-wrapper {
  display: flex;
}

.is-testimonial-name {
  padding-left: .75em;
}

.is-testimonial-photo {
  height: 2.5em;
  border-radius: 1000em;
}

.person-title {
  padding-bottom: .45em;
  padding-top: .7em;
  margin-bottom: 0em;
}

.is-advisor {
  margin: 0em 1em 0em 1em;
}

.is-early-bird {
  background-color: rgba(255,255,255,.05);
  font-size: .9em;
  color: white;
  border-radius: 100em;
  padding: .5725em .35em .35em .35em;
  max-width: 11.5em;
  margin: 0 auto 1em auto;
  opacity: .5;
}

.is-early-bird:hover {
  opacity: 1;
}

.pricing-head {
  padding-bottom: 3em;
  max-width: 40em;
  margin: auto;
  z-index: 10 !important;
}
.pricing-title {
  font-weight: 700 !important;
}
.pricing-sub {
  opacity: .5;
  padding-bottom: 2em;
}
.pricing-below-text {
  padding-top: 1em;
  color: #3c484e;
  opacity: .5;
  max-width: 30em;
  margin: auto;
}
.pricing-card {
  border-radius: .4em;
  margin: auto;
  border: 1px solid rgba(41,49,62,.08);
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05) !important;
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05) !important;
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05) !important;
}
.pricing-card:hover {
  -webkit-box-shadow: 0px 7px 15px 0px rgba(0,0,0,0.075) !important;
  -moz-box-shadow: 0px 7px 15px 0px rgba(0,0,0,0.075) !important;
  box-shadow: 0px 7px 15px 0px rgba(0,0,0,0.075) !important;
}

.pricing-card-content {
  text-align: left;
  color: #191f21;
}

.pricing-card-content-boost {
  text-align: left;
  color: #191f21;
  background-color: white;
}

.pricing-icon-yes {
  color: #23A534;
}

.pricing-icon-no {
   color: #D8233A;
}

.is-pricing-line {
  line-height: 1.4em;
  font-weight: 600;
  font-size: 19px;
}

.is-pricing-line-no {
  font-weight: normal !important;
  opacity: .5;
}

.is-boost-pricing-line {
  line-height: 1.4em;
  font-weight: 600;
  font-size: 16px;
}

.pricing-card-emphasis {
  border-radius: .4em;
  margin: auto;
  border: 2px solid rgb(29,37,49);
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05) !important;
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05) !important;
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05) !important;
}
.pricing-card-emphasis:hover {
  -webkit-box-shadow: 0px 7px 15px 0px rgba(0,0,0,0.075) !important;
  -moz-box-shadow: 0px 7px 15px 0px rgba(0,0,0,0.075) !important;
  box-shadow: 0px 7px 15px 0px rgba(0,0,0,0.075) !important;
}

.pricing-card-boost {
  border-radius: .4em;
  max-width: 60em !important;
  margin: auto;
  border: 1px solid rgba(0,105,255,.125);
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05) !important;
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05) !important;
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05) !important;
}
.pricing-card-boost:hover {
  -webkit-box-shadow: 0px 7px 15px 0px rgba(0,0,0,0.075) !important;
  -moz-box-shadow: 0px 7px 15px 0px rgba(0,0,0,0.075) !important;
  box-shadow: 0px 7px 15px 0px rgba(0,0,0,0.075) !important;
}

.has-big-emphasis {
  background-color: #F01D65;
  border-radius: 2em;
  padding: .305em .725em .15em .725em !important;
  color: white !important;
  margin-bottom: 1em !important;
  display: inline-block !important;
  font-weight: 600;
}

.has-dark-emphasis {
  background-color: #1D2531;
  border-radius: 2em;
  padding: .305em .725em .15em .725em !important;
  color: white !important;
  margin-bottom: 1em !important;
  display: inline-block !important;
  font-weight: 600;
}

.pricing-card-header {
  padding-bottom: 1em;
  border-bottom: 1px solid rgba(41,49,62,.08);
  background-color: rgba(0,105,255,.125);
}

.pricing-card-header-emphasis {
  padding-top: 1em;
  padding-bottom: 1em;

  background-color: #f5f9ff;
}

.is-most-popular {
  border-bottom: 1px solid rgba(0,105,255,.08);
  border-top-right-radius: .4em;
  border-top-left-radius: .4em;
}

.is-not-included {
  text-decoration: line-through;
  opacity: .5;
  font-weight: 400 !important;
}

.is-powered-by {
  font-weight: 600;
  color: #a9adb2;
}

.is-pricing-switch {
  padding-bottom: 2em;
}

.switch[type=checkbox].is-medium:checked+label::before, .switch[type=checkbox].is-medium:checked+label:before {
  background: #1D2531;
  border-radius: 4px;
}

.switch[type=checkbox].is-medium+label::before, .switch[type=checkbox].is-medium+label:before {
  background: rgba(255,255,255,.15);
}

.switch[type=checkbox].is-medium+label {
  padding-left: 4.8rem;
  padding-top: .245rem;
}

.product-hunt {
  background-color: #29313e;
  padding: 1em;
  max-width: 40em;
  border-radius: .5em;
  margin: auto;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
}
.product-hunt:hover {
  opacity: 1;
  background-color: #29313e;
  padding: 1em;
  max-width: 40em;
  border-radius: .5em;
  margin: auto;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
}

.is-transparent {
  opacity: .5;
}

.navbar {
  background-color: transparent !important;
  padding: 3em 8.25em 3em 8.25em;
}

.control-mobile {
  text-align: right;
  margin-right: 0;
}

.app {
  min-height: 100vh; /* will cover the 100% of viewport */
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 300px; /* height of your footer */
}

.has-bg-image {
  background-image: url('../../assets/hero-2.svg')center center;
  background-size: cover;
}

.two {
  background-color: #F01D65;
  color: white;
  float: right;
  margin-left: 1.2em;
  padding: .2em .4em .2em .4em;
  border-radius: .3em;
  font-size: 15px !important;
  font-weight: 800 !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

.is-dash-title {
  display: inline-block;
}

.is-action-outline {
  background-color: #F01D65;
  color: white;
  margin-left: 1.2em;
  padding: .2em .4em .2em .4em;
  border-radius: .3em;
  font-size: 15px !important;
  font-weight: 800 !important;

  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

.two-float-left {
  background-color: #F01D65;
  color: white;
  float: left;
  margin-left: 0em;
  padding: .2em .4em .2em .4em;
  border-radius: .3em;
  font-size: 15px !important;
  font-weight: 800 !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

.is-trial-action-outline {

  background-color: #F01D65;
  color: white;
  margin-left: 1.2em;
  margin-bottom: 1em;
  padding: .2em .4em .2em .4em;
  border-radius: .3em;
  font-size: 12px !important;
  font-weight: 800 !important;

  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

.is-trial-action-outline-right {
  display: inline;
  line-height: 1.5em;

  background-color: #F01D65;
  color: white;
  margin-left: 25em;
  margin-bottom: 1em;
  padding: .2em .4em .2em .4em;
  border-radius: .3em;
  font-size: 12px !important;
  font-weight: 800 !important;

  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}


.two-white {
  background-color: rgba(255,255,255,.1);
  color: white;
  float: right;
  margin-left: 1.2em;
  padding: .2em .4em .2em .4em;
  border-radius: .3em;
  font-size: 15px !important;
  font-weight: 700;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

.two-dark {
  background-color: #1D2531;
  color: white;
  float: right;
  margin-left: 1em;
  padding: .2em .4em .2em .4em;
  border-radius: .3em;
  font-size: 15px !important;
  font-weight: 600;
  letter-spacing: .5px;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

.two-dark-dash {
  background-color: #1D2531;
  color: white;

  margin-left: .5em;
  padding: .2em .4em .2em .4em;
  border-radius: .3em;
  font-size: 15px !important;
  font-weight: 600;
  letter-spacing: .5px;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

.two-pink-dash {
  background-color: #F01D65;
  color: white;

  margin-left: .5em;
  padding: .2em .4em .2em .4em;
  border-radius: .3em;
  font-size: 15px !important;
  font-weight: 600;
  letter-spacing: .5px;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

.is-outline {
  border: 1.5px solid rgb(255,255,255) !important;
  color: black !important;
  background-color: white !important;
  font-weight: 600;
  margin: 0em .1em 0em .1em;
}

.is-outline:hover {
  background-color: rgb(255,255,255) !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.03);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.03);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.03);
}

.is-stripe-nav {
  font-weight: 700 !important;
  background-color: #1a46ff !important;
  border: none !important;
  font-weight: bold !important;
  color: white !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}
.is-stripe-nav:hover {
  color: white !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

.is-stripe-pricing {
  background-color: #0069ff !important;
  border: none !important;
  font-weight: 700 !important;
  color: white !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}
.is-stripe-pricing:hover {
  color: white !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

.is-press-logo {
  font-weight: 600 !important;
  padding-bottom: 2.5em;
}

.is-clear {
  background-color: rgba(255,255,255,.05) !important;
  border: none !important;
  font-weight: 600;
  margin: 0em .1em 0em .1em;
  color: white !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
}

.is-clear:hover {
  background-color: rgba(255,255,255,.07) !important;
  color: white !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.03);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.03);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.03);
}

.is-clear-border {
  background-color: transparent !important;
  border: solid 3px rgba(255,255,255,.05) !important;
  font-weight: 600;
  margin: 0em .1em 0em .1em;
  color: white !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
}

.is-nav-dropdown {
  margin-right: 13.6px;
}

.is-white {
  background-color: white !important;
  border: none !important;
  font-weight: 700;
  margin: 0em .1em 0em .1em;
  color: #191f21 !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
}

.is-white:hover {
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.03);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.03);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.03);
}

.is-black {
  background-color: #1D2531 !important;
  border: none !important;
  font-weight: 700;
  margin: 0em .1em 0em .1em;
  color: white !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
}

.is-black:hover {
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.03);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.03);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.03);
}

.is-stripe-middle {
  background-color: #0069ff !important;
  border: none !important;
  font-weight: 700 !important;
  margin-top: 1em;
  color: white !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

.is-stripe-middle:hover {
  color: white !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

.is-stripe-middle-big {
  padding: 1.5em !important;
  font-size: 1.15em !important;
  background-color: #457fff !important;
  border: none !important;
  font-weight: 700 !important;
  margin-top: 1em;
  color: white !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

.is-stripe-middle-big:hover {
  color: white !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}
.is-hiw-hero {
  font-size: 1.4em !important;
  background-color: transparent !important;
  border: 1px solid white !important;
  font-weight: 300 !important;
  color: white !important;
  margin: 0em .5em 0em .5em;
  opacity: .5;
  padding-top: 11px !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.125);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.125);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.125);
}

.is-hiw-hero:hover {
  color: white !important;
  opacity: .75;
}

.is-stripe-hero {
  font-size: 1.4em !important;
  background-color: #0069ff !important;
  border: none !important;
  font-weight: 700 !important;
  color: white !important;
  margin: 0em .5em 0em .5em;
  padding-top: 11px !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.125);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.125);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.125);
}

.is-stripe-hero:hover {
  color: white !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.25);
}

.is-stripe-hero-exclamation {
  font-size: 1.4em !important;
  background-color: #F01D65 !important;
  border: none !important;
  font-weight: 700 !important;
  color: white !important;
  margin: 0em .5em 0em .5em;
  padding-top: 9px !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  z-index: 100;
}

.is-stripe-hero-exclamation:hover {
  color: white !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.125);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.125);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.125);
}

.is-stripe-hero-podcasts {
  font-size: 1.4em !important;
  background-color: #1D2531 !important;
  border: none !important;
  font-weight: 700 !important;
  color: white !important;
  margin: 0em .5em 0em .5em;
  padding-top: 9px !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  z-index: 100;
}

.is-stripe-hero-podcasts:hover {
  color: white !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.125);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.125);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.125);
}

.is-stripe-hero-boost {
  font-size: 1.4em !important;
  background-color: #0060ff !important;
  border: none !important;
  font-weight: 700 !important;
  color: white !important;
  margin: 0em .5em 0em .5em;
  padding-top: 9px !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  z-index: 100;
}

.is-stripe-hero-boost:hover {
  color: white !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.125);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.125);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.125);
}

.is-boost-blue {
  background-color: #0060FF !important;
  border: none !important;
  font-weight: 700;
  margin: 0em .1em 0em .1em;
  color: white !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.025);
}

.is-boost-blue:hover {
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.03);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.03);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.03);
}

.is-hiw-hero-exclamation {
  font-size: 1.4em !important;
  background-color: transparent !important;
  border: 2px solid rgba(255,255,255,.15) !important;
  font-weight: 500 !important;
  color: white !important;
  margin: 0em .5em 0em .5em;
  padding-top: 8px !important;
}

.is-hiw-hero-exclamation:hover {
  border: 2px solid rgba(255,255,255,.2) !important;
}

.is-stripe-big {
  font-size: 1.2em !important;
  background-color: #457fff !important;
  border: none !important;
  font-weight: 700 !important;
  color: white !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.125);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.125);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.125);
}

.is-stripe-big:hover {
  color: white !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.175);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.175);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.175);
}

.stripe-button {
  background-color: #457fff;
  font-weight: 700;
  color: white !important;
  padding: .75em;
  border-radius: 5px;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.125);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.125);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.125);
  cursor: pointer;
}

.stripe-button:hover,.stripe-button-nav:hover {
  color: white !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.175);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.175);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.175);
}


.pay-button {
  background-color: #457fff;
  font-weight: 700;
  color: white !important;
  padding: .75em;
  border-radius: 5px;
  cursor: pointer;
}

.pay-button:hover {
  color: white !important;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.1);
}

.is-small-header {
  font-size: .8em;
  font-weight: 800;
  color: #3c484e;
  opacity: .35;
  padding-bottom: .5em;
}

.is-link {
  color: inherit;
  border-bottom: 2px solid rgba(41,49,62,.1);
  font-weight: 600;
}

.is-link:hover {
  border-bottom: 2px solid rgba(41,49,62,.2);
}

.is-dark-link {
  color: inherit;
  border-bottom: 2px solid rgba(255,255,255,.075);
}

.is-dark-link:hover {
  color: inherit;
  border-bottom: 2px solid rgba(255,255,255,.125);
}

.footer {
  bottom: 0;
  width: 100%;
  color: white;
  background-color: #1D2531 !important;
  padding-bottom: 48px !important;
}

.dash-upper-nav {
  height: 60px;
  background-color: rgba(255,255,255,.9);
  padding: 1em 1em 1em 3em;
  margin-top: 1.01em;
  margin-bottom: 0em;
  border-top: 1px solid #E3E8EF;
}

.dash-upper-nav-icon {
  width: 1em;
}

.title-for-dash {
  font-size: 20px;
  line-height: 1.55em;
}

.dash-upper-nav-2 {
  height: 49px;
  background-color: #f5f8fb;
  width: 100%;
  padding: 89px 1em 32px 3em;
  margin-top: 0em;
  margin-bottom: 0em;
  border-bottom: 1px solid #E3E8EF;

  z-index: 1000;
}

.is-dash-intro {
  margin-bottom: 2em;
  margin-left: 0em;
  border-radius: .4em;
}

.dash-intro-title {
  font-size: 1.3em;
  font-weight: 800;
  padding-bottom: .3em;
}

/* Dashboard styling */

.dashnav-v2 {
  background-color: #1D2531;
  float: left;
  top: 0;
  bottom: 0;
  left: 0;
  width: 180px;
  z-index: 1000;
  position: fixed;
  border-right: solid 1px rgba(0,0,0,.1)
}

.dashnav {
  background-color: #1D2531;
  float: left;
  top: 0;
  bottom: 0;
  left: 0;
  width: 90px;
  z-index: 1000;
  position: fixed;
}

.dashnav-inner {
  height: 100vh;
  position: relative;
}

.dashnav-logo {
  max-width: 50px;
  margin: 5px auto 1em auto !important;
  -webkit-box-shadow: 0 3px 5px #111222;
  -moz-box-shadow: 0 3px 5px #111222;
  box-shadow: 0 3px 5px #111222;
  border-radius: .4em;
}

.is-boost-message {
  background-color: #F01D65;
  color: white;
  padding: 1em;
  border-radius: .4em;
  max-width: 1034.5px;
  margin-bottom: 2em;
}

.is-trial-search-tab-active {
  margin: 0em 1em 1em 0em;
  padding: 0em;
  background-color: #3273dc;
  border-radius: .4em;
  color: white !important;
  border-bottom: 0px solid white !important;
  font-weight: bold;
}

.is-trial-search-tab-active:hover {
  color: white !important;
}

.is-trial-search-tab-inactive {
  margin: 0em 1em 1em 0em;
  padding: 0em;
  background-color: #7E94A3;
  border-radius: .4em;
  color: white !important;
  border-bottom: 0px solid white !important;
}

.is-trial-search-tab-inactive:hover {
  color: white !important;
  font-weight: bold;
}

.tabs a {
  border-bottom: 0px solid white !important;
  color: white;
}

.is-tooltip-primary {
  color: red !important;
}

.is-filter-card {
  background-color: white;
  padding: 1em;
  margin: 0em 1em 1em 0em !important;
  border-radius: .4em;
  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
}

.is-trial-filter-header {
  margin-bottom: .5em !important;
}

.is-trial-filter-para {
  margin-bottom: 1em;
}

.is-dash-left-action-card {
  background-color: #1D2531;
  color: white;
  padding: 1.9em;
  margin: 0em 1em 1em 0em !important;
  border-radius: .4em;
  float: left;
  display: inline-block !important;

  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
}

.is-trial-search-tab {
  font-weight: 600;
  padding-bottom: .5em;
}

.is-search-card {
  background-color: white;
  padding: 1em;
  margin: 0em 1em 1em 0em !important;
  border-radius: .4em;
  max-width: 38em;
  min-width: 38em;
  float: left;
  display: inline-block !important;

  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
}

.is-search-card:hover {
  -webkit-box-shadow: 0 5px 5px #eff4f9;
  -moz-box-shadow: 0 5px 5px #eff4f9;
  box-shadow: 0 5px 5px #eff4f9;
}

.search-card-header {
  display: flex;
  margin-bottom: 1.5em;
}

.ais-Hits-item {
  display: inline-block;
  float: left;
  width: 100%;
}

.ais-Hits-list {
  margin: auto !important;
}

.search-list {
  display: block;
}

.ais-RefinementList-labelText {
  display: inline-block;
  padding: 0em 3px 5px 12px;
  border-radius: .4em;
  margin: 0px 4px 0px 0px;
  font-size: 1em;
  text-transform: capitalize;
}

.ais-RefinementList-count {
  color: #8493a0;
  font-weight: 500;
  border-radius: 1em;

  padding: 2px 6px;
  font-size: 13px;
  border: solid 1px #eff3f7;
  background-color: white;
}


.ais-SearchBox-input {
  width: 100%;
}

.ais-SearchBox-input {
  color: #1d2531;
  background-color: white;
  padding: 10px 12px 12px 12px;
  border-radius: 7px;
  border: none;
  font-size: 1em;
  height: 45px;
  width: 100%;
  border: 1px solid #dfe8ef;
  box-shadow: rgba(10,40,91,.035) 0px 2px 5px, rgba(10,40,91,.035) 0px 5px 12px;
}


.ais-SearchBox-input:hover,.ais-SearchBox-input:active {
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
}

.ais-SearchBox-submit,.ais-SearchBox-reset {
  display: none;
}

.ais-SearchBox-form {
  padding-left: 90px;
  margin: 0;
  padding: 0;
}


.dash-new-list {
  background-color: black;
  padding: 1em;
  border-radius: .4em;
  border: none;
  font-size: 1em;
  margin: 2em auto 2em auto !important;
  float: right;
  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
}

.list-page-title {
  margin-bottom: 0em;
  padding-bottom: .75em;
}

.loading {
  font-size: 1.25em;
  font-weight: bold;
  background-color: white;
  border-radius: .4em;
  width: 250px;
  margin: 5em auto auto auto;
  padding: 1em;
  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
}

.is-dashnav-icon {
  max-width: 50px;
  padding: .775em;
  margin-top: 1em;
  margin-bottom: 0em;
  border-radius: 5px;
  color: #bfe2ff !important;
  background-color: rgba(255,255,255,.04);
}

.is-dashnav-active {
  background-color: rgba(255,255,255,.05);
}

data-tooltip {
  background-color: red;
  opacity: 1;
}

/*.has-margin-left-for-dashnav-v2{
  margin-left: 90px;
}*/

.has-margin-left-for-dashnav-v2 {
  margin-left: 232px;
}

.has-padding-left-for-dashnav-v2{
  padding-left: 90px;
}

.has-padding-left-for-dashnav-v2 {
  padding-left: 180px;
}

.is-dashnav-v2-title {
  color: white;
  font-weight: bold;
  font-size: 1em;
  padding-top: .325em;
  margin-bottom: 0em;
}

.has-dashboard-padding {
  padding-left: 3em;
  padding-right: 3em;
}

.is-media-list {
  background-color: white;
  padding: 1em !important;
  border-radius: .4em;

  display: inline-block;
  margin: .5em 1em .5em 0em;
  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
}

.is-media-list:hover {
  -webkit-box-shadow: 0 5px 5px #eff4f9;
  -moz-box-shadow: 0 5px 5px #eff4f9;
  box-shadow: 0 5px 5px #eff4f9;
}

.is-media-list-title {
  font-size: 1.1em;
  color: inherit;
  margin: 0 !important;
  padding: .1em .24em 0em 0em;
  float: left;
  font-weight: 600;
}

.is-media-list-title:hover {
  text-decoration: none;
  color: black;
}

.is-list-icon {
  height: 25px;
  float: left;
  display: inline-block;
  margin: .14em .8em 0em 0em;
}

.dashboard-new-list-button {
  background-image: linear-gradient(0deg,#067ED8 0,#1791ED 100%);
  padding: .8em 1em .8em 1em !important;
  border-radius: .4em;
  display: block;
  float: left;
  color: white !important;
  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
  cursor: pointer;
}

.dashboard-new-list-button-text {
  color: white !important;
  font-weight: 600;
  font-size: 1em;
  margin: 0;
  text-decoration: none !important;
}

.dashboard-new-list-button-icon {
  height: 25px;
  float: left;
  opacity: .25;
  display: inline-block;
  margin: .14em .8em 0em 0em;
}


.tip {
  opacity: .45;
  margin-bottom: 0em;
}

.ais-Pagination-button {
  margin-bottom: 10em;
}

/*.ais-Pagination {
  font-size: 16px;
  padding: 8px 12px;
  background-color: white;
  border: solid 1px #dfe8ef;
  color: #4d5a60;
  border-radius: 7px;
  font-weight: 600;
  box-shadow: rgba(191, 191, 191, 0.15) 0px 2px 2px 1px;
  margin-left: 12px;
  margin-top: -9px;
}*/
.ais-Pagination-list {
  margin: auto;
}
.ais-Pagination-item {
  display: inline-block;
  padding: 0px 2px;
  color: #1D2531 !important;
}

.ais-HitsPerPage-select {
  margin: 0em;
  margin-top: 2.75em;
  margin-left: 2em;
}

.select {
  margin: 2em !important;
}

.is-account {
  margin: auto;
  text-align: center;
  padding: 2em;
  max-width: 28em;
  border-radius: .4em;
  min-height: 13em;
  background-color: white;
  border: 1px solid #dfe8ef;
  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
}

.is-sign-in-up {
  margin: auto;
  text-align: center;
  padding: 2em;
  max-width: 25em;
  border-radius: .4em;
  min-height: 13em;
  background-color: white;
  border: 1px solid #dfe8ef;
  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
}


.is-account-for-height-bug {
  margin: auto;
  text-align: center;
  padding: 2em;
  max-width: 25em;
  border-radius: .4em;
  background-color: white;
  height: 22em;
  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
}

.is-checkout-popup {
  margin: auto;
  text-align: center;
  padding: 2em;
  max-width: 25em;
  border-radius: .4em;
  background-color: white;
  -webkit-box-shadow: 0 3px 2px #222;
  -moz-box-shadow: 0 3px 2px #222;
  box-shadow: 0 3px 2px #222;
}

.is-standard-button {
  background-color: white;
  border: 1px solid #eff4f9 !important;
  font-size: 1em;
  padding: .5em .75em .5em .75em;
  border-radius: .4em;
  color: #1D2531;
  cursor: pointer;
}

.icon {
  color: white;
  opacity: .5;
}

.is-action-button {
  background-image: linear-gradient(0deg,#354052 0,#4A5971 100%);
  color: white !important;
  font-size: 1em;
  padding: .6em .75em .65em .75em;
  border-radius: .25em;
  font-weight: 600;
  text-decoration: none !important;
  border: none;
  cursor: pointer;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.02);
  -moz-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.02);
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.02);
}

.is-action-button:hover {
  color: white !important;
}

.is-action-button-icon {
  height: 25px;
  float: right;
  opacity: .25;
  display: inline-block;
  margin: .14em 0em 0em .4em;
}

.is-success-button {
  background-color: #02ab5c;

  color: white !important;
  font-size: 1em;
  padding: .6em .75em .65em .75em;
  border-radius: .25em;
  font-weight: 600;
  text-decoration: none !important;
  border: none;
  cursor: pointer;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.02);
  -moz-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.02);
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.02);
}

.bulk-add-pagination {
  float: none;
  margin-bottom: 1em;
  text-align: center;
}

.is-pricing-button {
  width: 100%;
  background-color: #003dff;
  color: white !important;
  font-size: 1.2em;
  padding: .5em .75em .5em .75em;
  border-radius: .4em;
  font-weight: 700;
  text-decoration: none !important;
  border: none;
  margin-top: 32px;
  margin-bottom: 8px;
  cursor: pointer;
}

.is-pricing-button:hover {
  color: white !important;
  font-style: italic;
}

.is-pricing-button-below {
  width: 100%;
  background-color: white;
  color: rgba(29, 37, 49, .75) !important;
  font-size: 1em;
  padding: .5em .75em .5em .75em;
  border-radius: .4em;
  font-weight: 700;
  text-decoration: none !important;
  border: 1px solid #eff4f9 !important;
  margin-top: 10px;
  margin-bottom: 8px;
  cursor: pointer;
}

.is-pricing-button-loading {
  width: 100%;
  background-color: rgba(255, 221, 87, .5);
  color: #1d2531;
  font-size: 1.2em;
  padding: .5em .75em .5em .75em;
  border-radius: .4em;
  font-weight: 700;
  text-decoration: none !important;
  border: none;
  margin-top: 32px;
  margin-bottom: 8px;
  cursor: pointer;
  font-style: italic;
}

.is-red-button {
  background-color: #D8233A;
  color: white;
  font-size: 1em;
  padding: .5em .75em .5em .75em;
  border-radius: .4em;
  font-weight: 600;
  margin-top: 1em !important;
  text-decoration: none !important;
  border: none;
  cursor: pointer;
}

.is-red-button:hover {
  color: white !important;
}

.is-tag-icon {
  height: 25px;
  float: left;
  opacity: .25;
  display: inline-block;
  margin: .14em .4em 0em 0em;
  color: #191919;
}

.is-industry-icon {
  height: 25px;
  float: left;
  opacity: .25;
  display: inline-block;
  margin: .14em .4em 0em 0em;
  color: #191919;
}

.is-add-to-list-button {
  cursor: pointer;
}

.search-card-name {
  margin: 0em;
  padding-left: .76em;
  padding-top: .76em;
}

.search-card-tags {
  margin: 0em -.75em 0em 0em;
}

.search-card-industry {
  display: flex !important;
}

.is-search-card-tag {
  display: inline-block;
  padding: .5em 1em .5em 1em;

  background-color: #7E94A3;
  border-radius: .4em;
  margin: 0em .75em .75em 0em;
  font-weight: 600;
  color: white;
  font-size: .85em;
}

.search-card-picture {
  border-radius: .4em;
}

.nav-dropdown-title-icon-group {
  display: flex;
}

.pricing-product-icon-group {
  display: flex;
}

.dashnav-v2-icon-group {
  display: flex;
  padding: .5em;
  margin: .5em;
  background-color: rgba(255,255,255,.03);
  border-radius: .4em;
}

.dashnav-v2-icon-group:hover {
  display: flex;
  padding: .5em;
  margin: .5em;
  background-color: rgba(255,255,255,.05);
}

.dashnav-v2-logo-group {
  display: flex;
  padding: .5em;
  margin: 1.55em .5em 0em .5em;
  background-color: rgba(0,0,0,.1);
  border-radius: .4em;
}

.dashnav-v2-logo-group-wrapper {
  padding-bottom: 1.55em;
  margin-bottom: 1.55em;
  border-bottom: solid 1px rgba(0,0,0,.1);
}

.dashnav-general-group-wrapper {
  padding-bottom: 1.05em;
  margin-bottom: 1.55em;
}

.is-dashnav-v2-bottom {
  bottom: 1.55em;
  position: absolute;
  width: 100%;
}

.dashnav-general-group-wrapper-bottom {
  width: 100%;
  position: absolute;
  bottom: 0em;
  padding-bottom: 1.05em;
  padding-top: 1.05em;
  border-top: solid 1px rgba(0,0,0,.1);
}

.nav-dropdown-icon {
  margin-right: .65em;
  margin-top: -.75em;
  max-width: 1.75em;
}

.dashnav-v2-icon {
  margin-right: .65em;
  max-width: 1.75em;
  max-height:  1.75em;
  height: auto;
}

.pricing-product-icon {
  margin-right: .65em;
  margin-top: -.75em;
  max-width: 1.75em;
}



.is-product-nav-description {
  margin: 0em;
  font-weight: normal;
}

.dropdown-content1 {
  background-color: white;
  border: 1px solid #eff4f9 !important;
  color: white !important;
  font-weight: 600;
  border-radius: .4em;
  padding: 0em;
  margin-top: .3em;
  min-width: 20em;
  z-index: 1000;

  -webkit-box-shadow: 0 3px 5px rgba(25,25,25,.05);
  -moz-box-shadow: 0 3px 5px rgba(25,25,25,.05);
  box-shadow: 0 3px 5px rgba(25,25,25,.05);
}

.dropdown-item1 {
  padding: 0em !important;
}

.dropdown-item1:hover {
  background-color: transparent !important;
}

.dropdown-list-name {
  padding: 1em;
  margin: .5em;
  border-radius: .4em;
}

.dropdown-list-name:hover {
  background-color: rgba(227,232,239,.3);

}

.is-dropdown-list-icon {
  height: 25px !important;
  float: left;
  /*opacity: .225;*/
  display: inline-block;
  margin: -.25em 1em 1em -.25em !important;
  color: #191919;
}

.search-card-contact-tag {
  color: #333333;
  display: inline-block;
  padding: .5em 1em .5em 1em;
  background-image: linear-gradient(0deg,#F3F5F7 0,#FFF 100%);
  border: 1px solid #E3E8EF;
  border-radius: .4em;
  margin: 0em .75em .75em 0em;
  font-weight: 600;
  font-size: .85em;
}

.search-card-contact-tag:hover {
  color: #191919 !important;
}

.search-card-industry-tag {
  display: inline-block;
  padding: .5em 1em .5em 1em;
  background-color: white;
  border: 1px solid #E3E8EF;
  border-radius: .4em;
  margin: 0em .75em .75em 0em;
  font-weight: 600;
  font-size: .85em;
  color: #333333;
}

.is-social-button-link {
  color: inherit;
}

.has-multiple-buttons {
  margin: 0em !important;
  padding-bottom: 1em;
  padding-top: .5em;
}

.is-csv-button {
  cursor: pointer;
}

.is-edit-button {
  margin-right: .5em !important;
  cursor: pointer;
  color: white !important;
}

.contact-saved {
  background-image: linear-gradient(0deg,#23A534 0,#28B53B 100%);
  color: white;
  border-radius: .4em;
  padding: .25em .5em .25em .5em;
  font-weight: 600 !important;
  margin-top: 1.1em !important;
  margin-bottom: 0em !important;
}

.discount-saved {
  background-image: linear-gradient(0deg,#23A534 0,#28B53B 100%);
  color: white;
  border-radius: .4em;
  padding: .25em .5em .25em .5em;
  font-weight: 600 !important;
  margin-top: 1.1em !important;
  margin-bottom: 0em !important;
  display: inline-block !important;
}

.discount-rejected {
  background-color: #D8233A;
  color: white;
  border-radius: .4em;
  padding: .25em .5em .25em .5em;
  font-weight: 600 !important;
  margin-top: 1.1em !important;
  margin-bottom: 0em !important;
  display: inline-block !important;
}

.primary-data-icon {
  margin-right: 4px;
  margin-top: 2px;
  color: white;
  opacity: .5;
}


.check-code-button {
  float: right;
  display: inline;
  margin-top: 4px;
  cursor: pointer;
}
.search-card-primary-data {
  margin-bottom: 1em;
}

.search-card-email {
  color: white;
  display: inline-block;
  padding: .5em 1em .5em 1em;
  background-color: #3273dc;

  border-radius: .4em;
  margin: 0em .75em .75em 0em;
  font-weight: 600;
  font-size: .85em;
}


.add-to-list-container {

}

.form-title {
  font-weight: 600;
  float: left;
}

.form-container {
  display: block;
  margin: auto;
  padding: 0em 2em 0em 2em;
}
/*
.form-input {
  width: 100%;
  background-color: rgba(245,248,251,.25) !important;
  border: solid 1.5px rgb(245,248,251) !important;
}
*/
.is-stripe-card-section {
  width: 100%;
  background-color: rgba(245,248,251,.25);
  border: solid 1.5px rgb(245,248,251);
  font-size: 1em;
  padding: .5em;
  border-radius: .4em;
  margin-top: .25em;
  margin-bottom: .5em;
  color: #303238 !important;
}

.is-coupon-form-title {
  margin-top: 1em;
}

.is-coupon-form {
  font-size: 1em;
  padding: .5em;
  border-radius: .4em;
  margin-top: .25em;
  margin-bottom: 1em;
  text-transform: uppercase;
  min-width: 12.5em;
}
.search-card-outlet {
  color: white;
  display: inline-block;
  padding: .5em 1em .5em 1em;
  background-color: #1D2531;

  border-radius: .4em;
  margin: 0em .75em .75em 0em;
  font-weight: 600;
  font-size: .85em;
}

.search-card-outlet:hover {
  color: white !important;
}
.is-feature-col {
  padding: 0em 2.5em 2.5em 2.5em !important;
}

.is-feature-para {
  opacity: .5;
  padding-top: .25em;
}

.tabs ul {
  border-bottom: 1px solid #dfe8ef;
}

.trial-search-wrapper {
  display: inline-block !important;
  margin: auto !important;
  background-color: #f5f8fb;
  max-width: 40em;
  border-radius: .4em;
  padding: 1em 1em 1.5em 1em;
  float: none;
  border: 1px solid rgba(227,232,239,.3);
  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
}

.trial-search-results-container {
  margin: auto !important;
}

.is-trial-search-card {
  text-align: left !important;
}

.is-premium-hidden {

}
/*
.ais-InstantSearch__root {
  margin: auto;
  max-width: 38em;
  height: 3em;
}*/

.dashboard-search-wrapper {
  max-width: 38em;
}

.underline {
  border-bottom: solid 2px #efefef;
  padding-bottom: .5px;
}

.add-to-new-list-wrapper {
  margin-top: 1.5em;
  margin-bottom: .4em;
}

.is-create-list-title {
  margin-bottom: 0em !important;
  padding-top: 2em;
}

.modal-background {
  background-color: #1D2531;
  opacity: .95;
}

.used-by {
  opacity:.5;
  padding-bottom: 1em;
}

.ais-Stats-text {
  opacity: .75;
}

.is-sign-up-form-input {
  font-size: 1em;
  margin: .35em 0em .35em 0em;
  padding: .5rem;
  border-radius: 3px;
}

.is-left-button-icon {
  margin-right: .65em;
}

.is-google-button {
  background-color: white;
  color: #1D2531;
  font-size: 1em;
  padding: .5em .75em .5em .75em;
  border-radius: .4em;
  font-weight: 600;
  text-decoration: none !important;
  border: 1px solid rgba(41,49,62,.08);
  margin-bottom: 1.5em;
  cursor: pointer;
}

.is-google-button:hover {
  color: black;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.02);
  -moz-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.02);
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.02);
}

.sign-up-or {
  font-weight: 600;
  opacity: .4;
  padding-top: .8em;
  padding-bottom: .525em;
  margin-bottom: 0em;
}

.is-sign-in-text {
  margin-bottom: 0em;
  padding-top: 0em;
  font-size: .8em;
}

.is-sign-up-button {
  background-image: linear-gradient(0deg,#067ED8 0,#1791ED 100%);
  color: white !important;
  font-size: 1.1em;
  padding: .5em .75em .5em .75em;
  border-radius: .4em;
  font-weight: 700;
  text-decoration: none !important;
  border: none;
  margin-top: 1em;
  margin-bottom: 0em;
  cursor: pointer;
}

.has-pointer-cursor {
  cursor: pointer;
}

.is-change-password-modal {
  margin-top: 3em;
}



.dash-footer {
  bottom: 0;
  width: 100%;
  background-color: white;
  color: #1D2531 !important;
  padding-bottom: 48px !important;
  position: static;
}

.is-new-journalist-button-home {
  background-color: none !important;
  color: #4A4A4A;
  padding: .75em;
  font-size: 1em;
  font-weight: bold;
  border: none;

  border-radius: .4em;

  margin-top: 1.5em;
  width: 12em;
  text-align: center;

  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
}

.is-new-journalist-button-home:hover {

  -webkit-box-shadow: 0 5px 5px #eff4f9;
  -moz-box-shadow: 0 5px 5px #eff4f9;
  box-shadow: 0 5px 5px #eff4f9;
}

.is-new-journalist-button-dash {
  background-color: none !important;
  color: #4A4A4A;
  padding: .75em;
  font-size: 1em;
  font-weight: bold;
  border: 1px solid #dfe8ef;
  border-radius: .25em;
  width: 12em;
  text-align: center;

  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
}

.is-new-journalist-button-dash:hover {

  -webkit-box-shadow: 0 5px 5px #eff4f9;
  -moz-box-shadow: 0 5px 5px #eff4f9;
  box-shadow: 0 5px 5px #eff4f9;
}

.is-hits-per-page-button {
  background-color: none !important;
  color: #4A4A4A;
  font-size: 1em;
  font-weight: bold;
  border: none;

  border-radius: .4em;

  text-align: center;

  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
}

.is-hits-per-page-button:hover {

  -webkit-box-shadow: 0 5px 5px #eff4f9;
  -moz-box-shadow: 0 5px 5px #eff4f9;
  box-shadow: 0 5px 5px #eff4f9;
}

/* End of dashboard styling */

/* Directory styling */


.is-profile-left-card {
  background-color: white;
  padding: 1em;
  margin: 0em 0em 1.6em 0em !important;
  border-radius: .4em;
  float: left;
  display: inline-block !important;

  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
}

.is-profile-left-action-card {
  background-color: #1D2531;
  color: white;
  padding: 1.9em;
  margin: 0em 0em 1.6em 0em !important;
  border-radius: .4em;
  float: left;
  display: inline-block !important;

  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
}

.is-profile-left-action-card:hover {
  -webkit-box-shadow: 0 5px 5px #eff4f9;
  -moz-box-shadow: 0 5px 5px #eff4f9;
  box-shadow: 0 5px 5px #eff4f9;
}

.is-action-card-title {
  font-size: 1.7em;
  font-weight: bold;
  line-height: normal !important;
  padding-bottom: .3em;
}

.is-action-card-para {
  font-size: 1;
  font-weight: normal;
  line-height: normal;
  margin: 0em;
  padding-bottom: 1.6em;
  opacity: .8;
}

.is-blue {
  background-color: #0060FF;
  border: none;
  color: white;
}

.is-profile-cta-button {
  font-size: 1.25em;
  font-weight: 500;
  width: 100%;
}

.is-profile-cta-button:hover {
  color: white;
  -webkit-box-shadow: 0 5px 5px #222;
  -moz-box-shadow: 0 5px 5px #222;
  box-shadow: 0 5px 5px #222;
}

.is-profile-right-buttons {
  margin: 0em 0em 1.5em 0em !important;
  display: flex;
}

.is-profile-right-buttons-columns {
  width: 100%;
  margin: 0em !important;
  padding: 0em !important;
}

.is-profile-right-buttons-column {
  margin: 0em !important;
  padding: 0em !important;
}

.is-profile-back-button {
  background-color: white !important;
  color: #4A4A4A;
  padding: .75em;
  font-size: 1em;
  font-weight: bold;
  border: none;
  width: 16em;

  display: flex;
  border-radius: .4em;
  /*width: 100%;*/

  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
}

.is-profile-back-button:hover {

  -webkit-box-shadow: 0 5px 5px #eff4f9;
  -moz-box-shadow: 0 5px 5px #eff4f9;
  box-shadow: 0 5px 5px #eff4f9;
}

.is-new-journalist-button-dir {
  background-color: none !important;
  color: #4A4A4A;
  padding: .75em;
  font-size: 1em;
  font-weight: bold;
  border: none;
  float: right !important;

  display: flex;
  border-radius: .4em;

  /*width: 100%;*/

  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
}

.is-new-journalist-button-dir:hover {

  -webkit-box-shadow: 0 5px 5px #eff4f9;
  -moz-box-shadow: 0 5px 5px #eff4f9;
  box-shadow: 0 5px 5px #eff4f9;
}

.is-profile-right-card {
  background-color: white;
  padding: 1em;
  margin: 0em 0em 1em 0em !important;
  border-radius: .4em;
  display: inline-block !important;
  float: right;
  width: 100%;

  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
}



.index-profile-card {
  background-color: white;
  padding: 1em;
  margin: 0em 0em 1em 0em !important;
  border-radius: .4em;
  width: 100%;
  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
}

.navbar-directory {
  background-color: #0069ff;
  padding: 0em 8.25em 0em 8.25em;
  display: flex;
}

.is-interests-title {
  font-weight: 600 !important;
}

.line-separator {
  border-top: 2px solid #E3E8EF;
  opacity: .25;
  width: 100%;
  padding-bottom: 1em;
}

.is-pagination-button {
  background-color: none !important;
  color: #4A4A4A;
  font-size: 1em;
  font-weight: bold;
  border: none;
  max-width: 26em;
  /*margin-left: 4em;*/
  border-radius: .4em;
  text-align: center;
  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
}

.main-dash-line-separator {
  border-top: 2px solid #E3E8EF;
  opacity: .25;
  width: 100%;
  padding-bottom: 2em;
  margin-top: 1em;
  max-width: 1034.5px;
}

.sequence-form-line-separator {
  border-top: 2px solid #E3E8EF;
  opacity: .25;
  width: 100%;
  padding-bottom: 2em;
  margin-top: 1em;
}

.is-directory-pagination-div {
  margin-top: 3em;
  margin-bottom: 3em;
}

.pagination-link {
  background-color: white;
  border: none;

  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
}

.pagination-link:hover {
  background-color: #E3E8EF;
  border: none;
  font-weight: bold;

  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
}

.pagination-link.is-current {
  background-color: #1D2531;
  border: none;
  font-weight: bold;

  -webkit-box-shadow: 0 3px 2px #eff4f9;
  -moz-box-shadow: 0 3px 2px #eff4f9;
  box-shadow: 0 3px 2px #eff4f9;
}

.is-fake-search-bar {
  color: rgba(74,74,74,.6);
}

html {
  /*overflow-y: initial !important;*/
  /*overflow-x: initial !important;*/
}

.twitter-bio::first-letter {
  text-transform: uppercase;
}

/* End of directory styling */



  @media only screen and (min-width: 700px) {
    .center-card {
      top: -35px;
    }
    #howitworks {
      margin-top: 0em;
    }
  }

  @media only screen and (max-width: 600px) {
    .journalist-card-url-tag {
      display: none;
    }
    .is-trial-upgrade-list-button {
      display: none;
    }
    .is-para {
      padding: 1em !important;
    }
    .is-para-case-study {
      padding: 1em !important;
    }
    .navbar {
      padding: 1em 3em 1em 3em;
    }
    .navbar-menu {
      background-color: #1c42f3;
      /*background: 0, 0, 0, 0.6;*/
    }
    .navbar-item.logo{
      width: 85%;
    }
    .is-hiw-hero {
      display: none !important;
    }
    .ais-Stats {
      display: inline-block;
    }
    .article-card-trial {
      max-width: 300px !important;
    }
    .is-search-card {
      background-color: white;
      padding: 1em;
      margin: 0em 0em 1em 0em !important;
      border-radius: .4em;
      min-width: 100%;
      float: left;
      display: inline-block !important;
      -webkit-box-shadow: 0 3px 2px #eff4f9;
      -moz-box-shadow: 0 3px 2px #eff4f9;
      box-shadow: 0 3px 2px #eff4f9;
    }
    .clear-section-card {
      padding: 0em 0em 0em 0em;
    }
    .title.is-4 {
      font-size:1em;
    }
    .dropdown {
      display: inline;
    }
    .is-nav-dropdown {
      margin-right: 0;
    }
    .dropdown-content1 {
      min-width: 19em;
    }
    .two-white {
      font-size: 8px !important;
    }
    .two-dark {
      font-size: 8px !important;
    }

  }
